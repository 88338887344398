<section class="inner-con pt-lg-4 pt-3">
  <div class="container">
    <div class="h5 mb-3">FAQ</div>
    <div class="h6 blue-text fw-bold">1. How do I create a BTA account?</div>
    <p>Creating an account with BTA is easy. Simply click the 'Register' button on our home page, fill in your details,
      and create your User ID and password to complete the registration process. It only takes a few minutes</p>
    <hr>
    <div class="h6 blue-text fw-bold">2. Can I change my password?</div>
    <p>Yes, you certainly can. Changing your password is a simple process that you can do on your own by clicking on
      “Forgot Password”, or you can call us for assistance if needed.</p>
    <hr>
    <div class="h6 blue-text fw-bold">3. What should I do if I forget my password?</div>
    <p>Forgot your password? No need to worry. Simply fill in your agency ID and click the 'Forgot Password' link under
      the login button. Your password will be sent to your registered email</p>
    <hr>
    <div class="h6 blue-text fw-bold">4. What benefits do members of <a [href]="liveUrl" target="_blank">{{websiteUrl
        }}</a> get?</div>
    <p>There are many perks to being a member of {{websiteUrl}}. Discover a world of opportunities as you navigate our
      user-friendly {{clientAbbr}} travel tool. Whether you are a travel agency, tour operator, or any other travel
      professional, our platform is designed to empower you. You are entitled to discounts on the sale of air tickets,
      hotel reservations, holiday packages, or any other service you sell. Additionally, you can conduct business in
      your desired currency</p>
    <hr>
    <div class="h6 blue-text fw-bold">5. If I need any assistance what should I do?</div>
    <p>You can simply click on the chatbot character Captain {{clientAbbr}} on the homepage.We take pride in providing
      excellent professional support. Our team, comprising a blend of travel and technical experts, is ready to assist
      you in the best possible way. Feel free to call us toll-free anytime (24x7) or send us an email.</p>
    <hr>
    <div class="h6 blue-text fw-bold">6. As a travel agent, can I add my own Markup?</div>
    <p>
      Yes, you can. To add your own markup, click on the control panel, followed by the preferences tab, and set the fee
      for airline tickets, hotels, or other services.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">7. How do I update my agency details?</div>
    <p>
      To update your agency details, log in to your account, go to the "Profile Settings" section, and make the
      necessary changes. Please contact our customer service staff if you need further assistance.
    </p>
    <hr>
    <div class="h6 blue-text fw-bold">8. Can I book multiple services in a single transaction?</div>
    <p>
      Yes, you can! Our platform allows you to combine flight tickets, hotel bookings, holiday packages, and other
      services into a single transaction for ease of use.
    </p>
    <hr>
    <div class="h6 blue-text fw-bold">9. Are there any membership fees to use <a [href]="liveUrl"
        target="_blank">{{websiteUrl }}</a>?</div>
    <p>
      No, it costs nothing to register and use our site. However, service fees may apply for certain transactions or
      premium features.
    </p>
    <hr>
    <div class="h6 blue-text fw-bold">10. How can I check the progress of my bookings?</div>
    <p>
      You can track all your bookings in real-time by logging into your account and navigating to the "My Bookings"
      section.
    </p>
    <hr>
    <div class="h6 blue-text fw-bold">11. What payment methods are accepted on <a [href]="liveUrl"
        target="_blank">{{websiteUrl }}</a>?</div>
    <p>
      We accept a variety of payment methods, including credit cards, debit cards, net banking, and UPI (where
      applicable). For travel agents, we also support wallet payments and direct agency credit in select regions.
    </p>
    <hr>
    <div class="h6 blue-text fw-bold">12. Can I cancel or modify a booking online?</div>
    <p>
      Yes, you can. Log in to your account, go to "My Bookings," and select the booking you want to cancel or modify.
      Please note that cancellation or modification fees may apply, depending on the supplier's policies.
    </p>
    <hr>
    <div class="h6 blue-text fw-bold">13. What currencies does <a [href]="liveUrl" target="_blank">{{websiteUrl }}</a>
      support?</div>
    <p>
      Our platform supports multiple currencies, allowing you to conduct business in your preferred currency. The
      currency options you have may change depending on your account type and area.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">14. How do I claim a refund for a canceled booking?</div>
    <p>
      Refund requests must be submitted in writing via email to the refund department (e.g., {{refundIn}}). Refund
      processing timelines vary based on the product and supplier's policies.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">15. Can I get customized holiday packages for my clients?</div>
    <p>
      Absolutely! Our team specializes in creating tailored holiday packages. Simply contact us with your client’s
      requirements, and we’ll design a package that fits their preferences and budget.
    </p>
    <hr>
    <div class="h6 blue-text fw-bold">16. How do I activate or deactivate agents under my account?</div>
    <p>
      As an agency owner, you can manage your agents by navigating to the "Agent Management" section under your
      dashboard. You can activate, deactivate, or modify their access permissions.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">17. Are there special offers for bulk bookings?</div>
    <p>
      Yes, <a [href]="liveUrl" target="_blank">{{websiteUrl |lowercase}}</a> provides discounts and special rates for
      bulk bookings. Contact our sales team for personalized deals.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">18. Can I use <a [href]="liveUrl" target="_blank">{{websiteUrl |lowercase }}</a>
      for visa applications?</div>
    <p>
      Yes, our platform supports visa application services. You can submit applications, track their status, and receive
      updates directly through your dashboard.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">19. What if I encounter a technical issue while using the platform?</div>
    <p>
      In case of any technical issues, reach out to our technical support team via the chatbot, email <a
        [href]="'mailto:' + apiSupportEmail">{{apiSupportEmail}}</a>, or 24x7 toll-free support line. We are here to
      resolve your queries quickly.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">20. Does <a [href]="liveUrl" target="_blank">{{websiteUrl |lowercase }}</a> offer
      training for new users?</div>
    <p>
      Yes, we offer free training sessions and tutorials for new users to help them navigate and maximize the benefits
      of our platform. Contact our team to schedule a session.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">21. How do I access reports and analytics for my bookings?</div>
    <p>
      Our platform includes a "Reports" section where you can generate detailed booking and sales analytics. These
      reports can be customized based on date, service type, or client.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">22. Can I integrate <a [href]="'mailto:' + apiSupportEmail">{{websiteUrl}}</a>
      with my own travel website?</div>
    <p>
      Our platform includes a "Reports" section where you can generate detailed booking and sales analytics. These
      reports can be customized based on date, service type, or client.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">23. What happens if a supplier is unresponsive?</div>
    <p>
      In rare cases where a supplier is unresponsive, our support team will intervene on your behalf to resolve the
      issue as quickly as possible.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">24. How secure is my data on <a
        [href]="'mailto:' + apiSupportEmail">{{websiteUrl}}</a>?</div>
    <p>
      We prioritize the security of your data and employ cutting-edge encryption technologies to safeguard your
      information. You can trust on us to safeguard all your data.
    </p>

    <hr>
    <div class="h6 blue-text fw-bold">25. Can I access <a
        [href]="'mailto:' + apiSupportEmail">{{websiteUrl|lowercase}}</a> on mobile devices?</div>
    <p>
      Yes, our platform is fully optimized for mobile devices, and we also offer a dedicated mobile app for on-the-go
      access to our services.
    </p>


  </div>
</section>