import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { DOCUMENT } from '@angular/common';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { environment } from '../../../environments/environment';

import { EventService } from '../../core/services/event.service';
import { AuthenticationService } from '../../core/services/auth.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/core/services/toast.service';
import { FlightService } from 'src/app/core/services/flight.service';
import { DataShareService } from 'src/app/core/services/datashare.sevice';

@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss'],
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {
  public logoUrl = environment?.logoUrl;
  public imageUrl = environment?.imageUrl;
  public logoHeight = environment?.logoHeight;
  proxyStatus: boolean = false;
  isProxy: boolean = false
  public userName: string;
  public mobileIconView: boolean = false;
  public profileIcon: boolean = false;
  public activeButton;
  public index = null;
  public totalAmount;
  element;
  configData;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  permissions = [];
  menuItems = [];
  showRechargeButton: boolean = false;
  public avalBal;
  public overDraft;
  public subIndex = null;

  listLang = [
    // { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    // { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    // { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    // { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('subMenu') subMenu: ElementRef;
  @ViewChild('topnav-menu-content') sideMenu: ElementRef;

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private authService: AuthenticationService,
    private storageService: StorageService,
    private tostrService: ToastService,
    private flightService: FlightService,
    private eventService: EventService,
    private elementRef: ElementRef,
    private dateShareService: DataShareService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });
  }

  @HostListener('document:click', ['$event'])onClick(): void {
    console.log('check');
  // onClickOutside(event?: Event) {
    // if (event && event.target) {
      // Check if the click was outside the component
      // if (!this.elementRef.nativeElement.contains(event.target)) {
        // Direct DOM query to find the next sibling element
        const dropdownElement = document.querySelectorAll('.dropdown');

    // if (event && event.target) {
    // Check if the click was outside the component
    // if (!this.elementRef.nativeElement.contains(event.target)) {
    // Direct DOM query to find the next sibling element

    if (dropdownElement) {
      dropdownElement.forEach((dropdown, i) => {
        if (dropdown) {
          const nextEl = dropdown.classList;
          if (nextEl) {
            // Check if the next element has the class 'show' and remove it

            if (dropdown.classList.contains('show')) {
              this.index = null;
              this.subIndex = null;
            }
          }
        }
      });
    }
  }

  private inactivityTimer: any;

  startInactivityTimer(): void {
    const dropdownElement = document.querySelectorAll('.dropdown');


    clearTimeout(this.inactivityTimer); // Reset timer
    this.inactivityTimer = setTimeout(() => {
      if (dropdownElement) {
        dropdownElement.forEach((dropdown, i) => {
          // if (i !== 0) {
          if (dropdown) {
            const nextEl = dropdown.classList;
            if (nextEl) {
              // Check if the next element has the class 'show' and remove it

              if (dropdown.classList.contains('show')) {
                this.index = null;
              }
            }
          }
        });
      }
    }, 5000); // 5 seconds of inactivity
  }
  ngOnInit(): void {
    // this.isProxy = this.storageService.getSessionStorageItem('proxy')
    // this.permissions = JSON.parse(
    //   JSON.stringify(this.storageService.getSessionStorageItem('permissions'))
    // );
    this.isProxy = this.storageService.getLocalStorageItem('proxy')
    this.permissions = JSON.parse(
      JSON.stringify(this.storageService.getLocalStorageItem('permissions'))
    );
    this.element = document.documentElement;

    this.initialize();

    this.configData = {
      suppressScrollX: true,
      wheelSpeed: 0.3,
    };

    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset =
          'https://book-staging-bucket.s3.ap-south-1.amazonaws.com/assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    //this.onClickOutside();
    this.userName = this.storageService.getLocalStorageItem('user_name');
    this.getAccountAmount();
    this.dateShareService.creaditDetails$.subscribe({
      next: (res: any) => {
        this.totalAmount = res;
        this.avalBal = (Number(res?.F) + Number(res?.L) + Number(res.V) + Number(res?.I) + Number(res?.O) + Number(res?.H)).toFixed(2);
        this.overDraft = (Number(res?.LC) + Number(res?.FC) + Number(res.VC) + Number(res?.IC) + Number(res?.OC) + Number(res?.HC)).toFixed(2);
      },
    });
    this.getProxyStatus();
    this.checkRecharge();
    this.selectedTab();
    // this.startInactivityTimer();
  }

  selectedTab() {
    MENU.map((item: any) => {
      if (this.router.url.includes(item?.label.toLowerCase())) {
        this.activeButton = item?.label
      }
      //this.activeButton
    })
  }

  checkRecharge() {
    this.flightService.checkRecharge().subscribe({
      next: (res: any) => {
        this.showRechargeButton = res?.data?.show_button
      }
    })
  }

  getAccountAmount() {
    this.flightService.getAccountBalance().subscribe((response) => {
      this.totalAmount = JSON.parse(JSON.stringify(response));
      this.dateShareService.setCreaditValue(this.totalAmount);
    });
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.tostrService.success('Logout successfully', '');
    this.router.navigate(['/']);
  }

  /**
   * On menu click
   */
  onMenuClick(event: any, index?, activeButton?) {
    event.stopPropagation();
    this.index = index;
    const nextEl = event.target.nextElementSibling;
    if (activeButton?.label.toLowerCase() === 'flight')
      this.router.navigateByUrl('/flight/flight-search')
    // if (activeButton.label.toLowerCase() === 'flight')
    //   this.router.navigateByUrl('/flight/flight-search')
    //  if (activeButton.label.toLowerCase() === 'flight')
    //   this.router.navigateByUrl('/flight/flight-search')

    if (activeButton) this.activeButton = activeButton.label;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        //parentEl.classList.remove("show");
      }
      //nextEl.classList.toggle("show");
    }
    return false;
  }
  onSubMenu(event: any, index) {
    event.stopPropagation();
    this.subIndex = index;
   
    const nextEl = event.target.nextElementSibling;

    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        //parentEl.classList.remove("show");
      }
      //nextEl.classList.toggle("show");
    }
    return false;
  }
  onSubMenuClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target;
    // Check if the clicked element is an <a> tag
    const submenuElement = this.subMenu.nativeElement as HTMLElement;

    if (submenuElement) {
      // Toggle 'show' class on the submenu
      submenuElement.classList.toggle('show');
    }
  }

  ngAfterViewInit() {
    this.activateMenu();
  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * Activates the menu
   */
  private activateMenu() {
    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
                const menuelement = document.getElementById(
                  'topnav-menu-content'
                );
                if (menuelement !== null)
                  if (menuelement.classList.contains('show'))
                    document
                      .getElementById('topnav-menu-content')
                      .classList.remove('show');
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    }
  }

  /**
   * Topbar light
   */
  topbarDark() {
    document.body.setAttribute('data-topbar', 'dark');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Set boxed width
   */
  boxedWidth() {
    document.body.setAttribute('data-layout-size', 'boxed');
    document.body.setAttribute('data-topbar', 'colored');
  }

  /**
   * Colored header
   */
  coloredHeader() {
    document.body.setAttribute('data-topbar', 'colored');
    document.body.removeAttribute('data-layout-size');
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLayout', layout);
  }

  /**
   * Initialize
   */
  initialize(): void {
    let menuList: any[] = MENU.map((item: any) => {
      let filteredObj: any = {};
      let secondArr = [];
      let thirdArr = [];
      Object.keys(this.permissions).forEach((key) => {
        if (key.toLowerCase() === item.label.toLowerCase()) {
          if (item.subItems && !item.link && this.permissions[key].view) {
            let subItemsFiltered = item.subItems.filter((subItem: any) => {
              let subItemPermissionKeys = Object.keys(this.permissions[key]);
              return subItemPermissionKeys.some((permissionKey) => {
                return (
                  permissionKey.toLowerCase() === subItem.label.toLowerCase() &&
                  this.permissions[key][permissionKey].view
                );
              });
            });
            subItemsFiltered.forEach((innerArray) => {
              if (!innerArray.link && innerArray.subItems) {
                if (item.label.toLowerCase() === key.toLowerCase()) {
                  Object.keys(this.permissions[key]).forEach((keys) => {
                    if (
                      keys.toLowerCase() === innerArray?.label.toLowerCase() &&
                      innerArray.subItems &&
                      !innerArray.link
                    ) {
                      secondArr = [];
                      innerArray?.subItems.forEach((secondArray) => {
                        thirdArr = [];
                        Object.keys(this.permissions[key][keys]).forEach(
                          (keyName) => {
                            if (
                              keyName.toLowerCase() ===
                              secondArray?.label.toLowerCase() &&
                              secondArray.subItems &&
                              !secondArray.link &&
                              this.permissions[key][keys][keyName]?.view ===
                              true
                            ) {
                              secondArray?.subItems?.forEach((thirdArray) => {
                                Object.keys(
                                  this.permissions[key][keys][keyName]
                                ).forEach((keyValue) => {
                                  if (
                                    keyValue.toLowerCase() ===
                                    thirdArray?.label.toLowerCase()
                                  ) {
                                    if (
                                      this.permissions[key][keys][keyName][
                                        keyValue
                                      ]?.view === true
                                    ) {
                                      thirdArr.push(thirdArray);
                                    }
                                  }
                                });
                              });

                              secondArray.subItems = thirdArr;
                              secondArr.push(secondArray);
                            } else {
                              if (
                                this.permissions[key][keys][keyName]?.view &&
                                keyName.toLowerCase() ==
                                secondArray?.label.toLowerCase()
                              ) {
                                secondArr.push(secondArray);
                              }
                            }
                          }
                        );
                      });

                      innerArray.subItems = secondArr;
                    }
                  });
                }
              }
            });
            if (subItemsFiltered.length > 0) {
              filteredObj = {
                id: item.id,
                label: item.label,
                link: item.link,
                icon: item.icon,
                subItems: subItemsFiltered,
              };
            }
          } else if (
            !item.subItems &&
            item.link &&
            this.permissions[key].view
          ) {
            filteredObj = item;
          }
        }
      });
      return filteredObj;
    }).filter((filteredItem: any) => Object.keys(filteredItem).length !== 0);

    this.menuItems = menuList;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  setProxyStatus(event: any) {
    this.profileIcon = false;
    const data = {
      status: event?.checked,
    };
    this.flightService.changeProxyStatus(data).subscribe({
      next: (response: any) => {
        this.tostrService.success(
          'Proxy status changed successfully',
          'Success'
        );
      },
      error: (error: any) => {
        this.tostrService.error('Unable to change proxy status', 'Error');
      },
    });
  }

  getProxyStatus() {
    this.flightService.getProxyStatus().subscribe({
      next: (response: any) => {
        this.proxyStatus = response?.data?.proxy_status;
      },
    });
  }
}
