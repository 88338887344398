<div class="container-fluid">
    <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 col-12 booking-engine"></div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="p-lg-5 p-3">
                <div class="h2 fw-bolder red-text">Our Booking Engine</div>
                <p class="lead">Our advanced booking engine is the backbone of seamless travel management, tailored specifically for B2B needs. Acting as your virtual travel agency, it provides a unified platform to search, compare, and book a wide array of travel services—flights, hotels, car rentals, and activities—all in one place.</p>
                <p>Designed with user-friendly features and powerful integrations, our booking engine ensures efficiency and accuracy, helping travel professionals serve their clients with ease and confidence. Experience the difference with a solution built to power your business.</p>
                <a routerLink="/static-pages/explorebooking" class="btn btn-lg blue-btn text-white">Explore</a>
            </div>
        </div>
       
    </div>
    <div class="row">
        
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-0 order-1">
            <div class="p-lg-5 p-3">
                
                <div class="h2 fw-bolder red-text">Our Distribution Solutions</div>
                <p class="lead">Our state-of-the-art travel distribution solutions are designed to seamlessly connect travel suppliers with travel sellers, bridging the gap between supply and demand in the travel ecosystem.</p>
                <p>With access to a vast inventory, efficient booking tools, and real-time data integration, our platform empowers travel sellers to deliver exceptional service and competitive pricing to their clients. Travelers, in turn, enjoy an unparalleled booking experience with more choices, cost-effective options, and the convenience of a streamlined process at every step of their journey.</p>
                <p>Experience the future of travel distribution with solutions built to elevate your business and enhance customer satisfaction globally.</p>
                <a routerLink="/static-pages/distribution" class="btn btn-lg blue-btn text-white">Explore</a>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 distribution-solution order-lg-1 order-0"></div>
        
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 white-label">
            
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12">
            <div class="p-lg-5 p-3">
                
                <div class="h2 fw-bolder red-text">TMC White Label Solution</div>
                <p class="lead">Our TMC White Label Solution is a fully customizable online booking platform, tailored to seamlessly integrate with your brand identity.</p>
                <p>This powerful solution offers essential functionalities, including flight, hotel, car rental, and activity search and booking capabilities, all powered by cutting-edge technology. Acting as a robust travel booking engine, it allows travel agencies to provide a comprehensive suite of services under their own branding, delivering exceptional value to clients while maintaining a consistent brand presence.</p>
                <p>Empower your agency with a solution that combines advanced technology with your unique identity.</p>
                <a routerLink="/static-pages/tmc" class="btn btn-lg blue-btn text-white order-lg-1 order-0">Explore</a>
            </div>
        </div>
        
    </div>
    <div class="row">
        
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-0 order-1">
            <div class="p-lg-5 p-3">
                
                <div class="h2 fw-bolder red-text">Unified API Solution</div>
                <p class="lead">Our Unified API serves as a powerful intermediary, streamlining the exchange of travel-related data across all sectors of the global travel industry.</p>
                <p>By bridging travel providers—airlines, hotels, car rental services—with distributors like online booking platforms and travel agencies, our API ensures seamless connectivity. Businesses gain real-time access to extensive travel inventories and services, enabling them to deliver exceptional customer experiences.</p>
                <p>With efficient integration and optimized workflows, our Unified API empowers your business to stay ahead in a competitive market while offering unmatched convenience and value to your clients.</p>
                <a routerLink="/static-pages/unifiedapi" class="btn btn-lg blue-btn text-white">Explore</a>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 unifiedAPI order-lg-1 order-0"></div>
    </div>
</div>