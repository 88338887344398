<div class="container">
    <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-0 order-1">

            <div class="h4 blue-text fw-bolder pt-4">India's Leading Travel Consolidator</div>
            <div class="h1 fw-bold"><span class="red-text">Comprehensive</span> <br>B2B Travel Solutions</div>
            <p>Unlock your travel business's potential with our all-in-one B2B travel agency. Simplify bookings, access
                exclusive deals, and deliver top-notch service to clients. Benefit from real-time support, tailored
                packages, and advanced technology for seamless operations. Partner with us to boost efficiency and
                profitability. Together, we’ll thrive in the dynamic travel industry! </p>
            <a routerLink="/account/auth/signup" class="btn btn-lg blue-btn text-white">Register Now</a>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-1 order-0 mob-height">
            <div class="position-relative h-100">
                <div class="bnr-flight"></div>
                <div class="bnr-fsearch"></div>
                <div class="bnr-holiday"></div>
                <div class="bnr-hotel"></div>
            </div>
        </div>
    </div>
</div>