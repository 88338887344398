import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { commonurls } from '../../commonBaseUrls';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {
  public liveUrl = commonurls?.liveUrl;
  public websiteUrl = commonurls?.websiteUrl;
  public apiSupportEmail = commonurls?.apisupportMail;
  public refundIn = commonurls?.refundInMail;
  public clientAbbr = environment?.clientAbbr;

}
