import { CommonModule } from "@angular/common";
import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { Router, RouterModule } from "@angular/router";
import { environment } from "src/environments/environment";

@Component({
  selector: 'BTA-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent {

  public logoUrl = environment?.logoUrl;
  public imageUrl = environment?.imageUrl;
  public clientAbbr = environment?.clientAbbr;
  public logoHeight = environment?.logoHeight;

  constructor(
    private router: Router
  ) { }

}