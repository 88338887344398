import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FaqComponent } from './faq/faq.component';
import { ContactusComponent } from './contactus/contactus.component';
import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
import { TermsofserviceComponent } from './termsofservice/termsofservice.component';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { CompanyBulletingComponent } from './company-bulleting/company-bulleting.component';
import { ProductsComponent } from './products/products.component';
import { ProductFlightComponent } from './product-flight/product-flight.component';
import { ProductHolidaysComponent } from './product-holidays/product-holidays.component';
import { ProductHotelComponent } from './product-hotel/product-hotel.component';
import { ExploreBookingEngineComponent } from './explore-booking-engine/explore-booking-engine.component';
import { ExploreDistributionComponent } from './explore-distribution/explore-distribution.component';
import { ExploreTmcComponent } from './explore-tmc/explore-tmc.component';
import { ExploreUnifiedapiComponent } from './explore-unifiedapi/explore-unifiedapi.component';
import { ProductBusComponent } from './product-bus/product-bus.component';
import { ProductCabComponent } from './products/product-cab/product-cab.component';
import { ProductEurailComponent } from './products/product-eurail/product-eurail.component';
import { ProductVisaComponent } from './products/product-visa/product-visa.component';
import { SharedModule } from '../shared.module';


@NgModule({
    declarations: [
        PrivacyPolicyComponent,
        FaqComponent,
        ContactusComponent,
        ProductFlightComponent,
        ProductsComponent,
        CompanyBulletingComponent,
        RefundPolicyComponent,
        TermsofserviceComponent,
        TermsandconditionsComponent,
        ProductHolidaysComponent,
        ProductHotelComponent,
        ExploreBookingEngineComponent,
        ExploreDistributionComponent,
        ExploreTmcComponent,
        ExploreUnifiedapiComponent,
        ProductBusComponent,
        ProductCabComponent,
        ProductEurailComponent,
        ProductVisaComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
        // RouterModule.forChild(routes),

    ],
    exports: [PrivacyPolicyComponent, 
        FaqComponent, 
        ContactusComponent,
        ProductBusComponent,
        ProductCabComponent,
        ProductEurailComponent,
        ProductVisaComponent,
        ProductFlightComponent,
        ProductsComponent,
        CompanyBulletingComponent,
        RefundPolicyComponent,
        TermsofserviceComponent,
        TermsandconditionsComponent,
        ProductHolidaysComponent,
        ProductHotelComponent,
        ExploreBookingEngineComponent,
        ExploreDistributionComponent,
        ExploreTmcComponent,
        ExploreUnifiedapiComponent,
        
    ]
})
export class BtaStaticPageModule { }