import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-product-bus',
    templateUrl: './product-bus.component.html',
    styleUrls: ['./product-bus.component.scss']
})

export class ProductBusComponent {
    public image_baseUrl = environment?.imageUrl;
    public clientAbbr = environment?.clientAbbr;

}