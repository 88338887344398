import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-product-flight',
  templateUrl: './product-flight.component.html',
  styleUrl: './product-flight.component.scss'
})
export class ProductFlightComponent {
  public imageUrl = environment?.imageUrl;
  public clientAbbr = environment?.clientAbbr;

  accordionData = [
    {
      id: 'flush-collapseOne',
      headingId: 'flush-headingOne',
      title: '1.	What is a B2B flight booking portal, and how does it benefit travel agents?',
      content: `A B2B flight booking portal is a platform that allows travel agents to book flights for their clients. It provides access to a wide range of flights, exclusive deals, and tools to make the booking process easy and, ultimately, help agents serve their clients more efficiently.`,
      expanded: true
    },
    {
      id: 'flush-collapseTwo',
      headingId: 'flush-headingTwo',
      title: '2. How can I access exclusive fares using the B2B portal?',
      content: `Travel agents can access special fares by using the portal, which often offers discounted rates and exclusive deals. These special fares enable agents to provide cost-effective options for their clients.`,
      expanded: false
    },
    {
      id: 'flush-collapseThree',
      headingId: 'flush-headingThree',
      title: '3. What kind of support is available for travel agents on the portal?',
      content: `The portal offers 24/7 professional support to assist travel agents with any inquiries or issues they may encounter during the booking process. This ensures a smooth and reliable experience for travel agents.`,
      expanded: false
    },
    {
      id: 'flush-collapseFour',
      headingId: 'flush-headingFour',
      title: '4. Can I earn commissions by using the B2B flight booking portal?',
      content: `Yes, travel agents can earn commissions on bookings made through the portal. This provides an additional source of revenue for agents and it increases their overall profitability.`,
      expanded: false
    },
    {
      id: 'flush-collapseFive',
      headingId: 'flush-headingFive',
      title: '5. Is the portal user-friendly for agents with varying levels of expertise?',
      content: `Absolutely. The B2B flight booking portal is designed with a user-friendly interface, making it easy for travel agents of all levels of expertise to navigate, search for flights, and manage bookings efficiently.`,
      expanded: false
    },
    {
      id: 'flush-collapseSix',
      headingId: 'flush-headingSix',
      title: '6. How does the portal ensure the security of financial transactions?',
      content: `The portal ensures secure transactions by offering various payment options and employing advanced security measures. Travel agents can trust that their financial information is handled with the utmost care and security.`,
      expanded: false
    }
  ];

  toggleAccordion(index: number): void {
    this.accordionData.forEach((item, i) => {
      item.expanded = i === index ? !item.expanded : false; // Toggle the clicked item and collapse others
    });
  }

}
