import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { HeaderService } from "src/app/core/services/header.service";
import { StorageService } from "src/app/core/services/storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'BTA-comprehensive-section',
  templateUrl: './comprehensive-section.component.html',
  styleUrls: ['./comprehensive-section.component.scss']
})

export class ComprehensiveSectionComponent {
public image_url = environment?.imageUrl
}