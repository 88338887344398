<!--Banner ssection starts here-->
<div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <h1 class="fb-h1 text-white">Visa services get easier with {{clientAbbr}} Travel Agency</h1>
                <div class="h4 text-white">Unlock international travel with ease</div>

                <p>Applying for a visa can sometimes be rather daunting. But i{{clientAbbr}} Travel Agency is there to
                    facilitate
                    visa arrangements for travel agents, clients, and corporations for easy access to global
                    destinations. We work with a wide range of visas, from tourist to business and work permits, for
                    over 100 countries.</p>
                <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
            <div class="col-lg-6">
                <img src="{{'../../../assets/images/products/visa/visa-banner-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<!--Banner ssection ends here-->

<!--home-section starts here-->
<section class="home-section bg-white">
    <div class="container pt-4 pb-0">
        <h2 class="text-center mb-3">Why you should choose our visa services?</h2>

        <div class="row g-2">
            <div class="col-lg-9">
                <div class="row g-2">
                    <div class="col-lg-3">
                        <div class="border rounded-4 mb-2 p-3 h-100">
                            <div class="mb-3" align="center"><img
                                    src="{{image_baseUrl+'assets/images/products/visa/Global-Accessibility.svg'}}"
                                    height="50">
                            </div>
                            <div class="text-center">
                                <h5 class="fw-bold">Global Accessibility</h5>
                                <p>Access visa services, from tourist to leading business venues.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="border rounded-4 mb-2 p-3 h-100">
                            <div class="mb-3" align="center"><img
                                    src="{{image_baseUrl+'assets/images/products/visa/Expert-Support.svg'}}"
                                    height="58"></div>
                            <div class="text-center">
                                <h5 class="fw-bold">Expert Support 24/7</h5>
                                <p>Help is a call away, wherever you are.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="border rounded-4 mb-2 p-3 h-100">
                            <div class="mb-3" align="center"><img
                                    src="{{image_baseUrl+'assets/images/products/visa/Express-Processing.svg'}}"
                                    height="50">
                            </div>
                            <div class="text-center">
                                <h5 class="fw-bold">Express Processing</h5>
                                <p>Free real-time status notifications on urgent applications.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="border rounded-4 mb-2 p-3 h-100">
                            <div class="mb-3" align="center"><img
                                    src="{{image_baseUrl+'assets/images/products/visa/Secure-Payment.svg'}}"
                                    height="50">
                            </div>
                            <div class="text-center">
                                <h5 class="fw-bold">Secure Payment</h5>
                                <p>All transactions made with the utmost safety in your local currency.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/visa/User-Centric-Interface.svg'}}"
                            height="50">
                    </div>
                    <div class="text-center">
                        <h5 class="fw-bold">User-Centric Interface</h5>
                        <p>Check on requirements, track your application, and make your bookings in a manner never
                            before.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<!--home-section ends here-->

<!--visa types starts here-->
<section class="home-section bg-white">
    <div class="container pt-4 pb-4">
        <div class="p-4 rounded-3 visa-type-con">

            <div class="row">
                <div class="col-lg-4 d-flex flex-column justify-content-center">
                    <h2 class="text-white mb-3 text-center">Types of visa </h2>
                    <img src="{{image_baseUrl+'assets/images/products/visa/types-of-visa.png'}}" class="img-fluid">
                </div>
                <div class="col-lg-8">

                    <div class="row mt-3">
                        <div class="col-lg-6 mb-lg-0 mb-3">
                            <div class="rounded-4 mb-2 p-3 h-100 bg-white bg-opacity-75">
                                <div class="mb-3" align="center"><img
                                        src="{{image_baseUrl+'assets/images/products/visa/Tourist-Visas.svg'}}"
                                        height="70"></div>
                                <div class="text-center">
                                    <h5 class="fw-bold  blue-text ">Tourist Visas </h5>
                                    <p>To see the glamorous destinations of the globe.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="rounded-4 mb-2 p-3 h-100 bg-white bg-opacity-75">
                                <div class="mb-3" align="center"><img
                                        src="{{image_baseUrl+'assets/images/products/visa/Business-Visas.svg'}}"
                                        height="70"></div>
                                <div class="text-center">
                                    <h5 class="fw-bold  blue-text">Business Visas </h5>
                                    <p>Quick and a reliable way out for professionals.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-lg-6  mb-lg-0 mb-3">
                            <div class="rounded-4 mb-2 p-3 h-100 bg-white bg-opacity-75">
                                <div class="mb-3" align="center"><img
                                        src="{{image_baseUrl+'assets/images/products/visa/Student-Visas.svg'}}"
                                        height="70"></div>
                                <div class="text-center">
                                    <h5 class="fw-bold  blue-text">Student Visas </h5>
                                    <p>We assist aspiring international students.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6  mb-lg-0 mb-3">
                            <div class="rounded-4 mb-2 p-3 h-100 bg-white bg-opacity-75">
                                <div class="mb-3" align="center"><img
                                        src="{{image_baseUrl+'assets/images/products/visa/Work-Permits-Residency.svg'}}"
                                        height="70"></div>
                                <div class="text-center">
                                    <h5 class="fw-bold  blue-text">Work Permits & Residency </h5>
                                    <p>Moving temporary or permanent.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!--home-section starts here-->
<section class="home-section bg-light pt-5 pb-3">
    <h3 class="fw-bold text-center mb-5">How it works?</h3>
    <div class="container">
        <div class="row mb-lg-4">
            <div class="col-lg-3 mb-lg-0 mb-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/visa/Sign-Up-user.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Sign Up</h5>
                    <p class="mb-0">Register on our platform for full access.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-lg-0 mb-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/visa/Document-Submission.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Document Submission</h5>
                    <p class="mb-0">Securely upload required documents.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-lg-0 mb-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/visa/Application-Tracking.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Application Tracking</h5>
                    <p class="mb-0">Stay informed on status with real-time notifications.</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/visa/Visa-Delivery.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Visa Delivery</h5>
                    <p class="mb-0">The visa or your approval notification will come through a mail notification.</p>
                </div>
            </div>

        </div>






    </div>
</section>
<!--home-section ends here-->

<!--home-section starts here-->
<section class="home-section pt-2 pb-2 bg-white">

    <div class="container pt-4 pb-4">
        <div class="row">
            <div class="col-lg-5   mb-lg-0 mb-3">
                <img src="{{image_baseUrl+'assets/images/products/visa/visa-offer.jpg'}}" class="img-fluid rounded-3">
            </div>
            <div class="col-lg-7 ">
                <h2>What we can offer you?</h2>
                <p>The {{clientAbbr}} Travel Agency offers a bespoke visa application solution; no-hassle processes,
                    clear
                    guidelines, affordable rates, and guaranteed service. We are your partners in the world of reliable
                    and timely delivery of visas, ensuring that your clients may travel anywhere in the world.</p>
                <div class="h4 fw-bold mb-3">Begin your journey with {{clientAbbr}} Travel Agency for secure, fast, and
                    professional visa support.</div>
                <a routerLink="/account/auth/signup" class="btn btn-lg blue-btn">Register Now</a>
            </div>
        </div>




    </div>
</section>
<!--home-section ends here-->