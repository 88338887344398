<div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 d-flex flex-column justify-content-center">
                <h1>{{clientAbbr}} Bus Booking Services for Travel Agencies</h1>

                <p>{{clientAbbr}}TravelAgency.com offers a robust bus booking platform that is convenient for travel
                    agencies
                    and corporate clients. Our well-thought-out system, on the other hand, allows company clients to
                    see and handle the assets in real-time, while it is also a part of the complete travel solution
                    package that connects with other essential services such as flights, hotels, and packages.</p>
                <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
            <div class="col-lg-4">
                <img src="{{image_baseUrl+'assets/images/products/bus-banner-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>

<section class="home-section bg-white">
    <div class="container pt-4 pb-4">
        <h2 class="text-center mb-3">Key Features</h2>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/Wide Inventory Access.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Wide Inventory Access</h5>
                    <p class="mb-0">Along with over a thousand bus networks, you can provide your clients with more
                        travel options.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/Custom Pricing-Markup.svg'}}" height="55"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Custom Pricing & Markup</h5>
                    <p class="mb-0">Adjusting pricing based on the agency’s needs can help achieve profitability.
                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/Agent-Friendly-Interface.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Agent-Friendly Interface</h5>
                    <p class="mb-0">The booking process can be simplified via an intuitive dashboard, where the
                        agents can look for, book, and manage bus tickets easily.</p>
                </div>
            </div>
        </div>

        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/API Integration.svg'}}" height="50">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">API Integration</h5>
                    <p class="mb-0">Use external APIs to link with actual suppliers and get the most recent
                        schedules and options.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/Real-Time Booking - Management.svg'}}" height="50">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Real-Time Booking & Management</h5>
                    <p class="mb-0">Get instant booking confirmations, change reservation details, and see to your
                        customers’ questions directly from your platform.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/Comprehensive Reporting Tools.svg'}}" height="55">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Comprehensive Reporting Tools</h5>
                    <p class="mb-0">Get targeted agent reports to make booking and commission monitoring easier.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/Payment Flexibility.svg'}}" height="60"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Payment Flexibility</h5>
                    <p class="mb-0">Make secure payments online or let agents use their credit limits for the
                        transactions.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-section bg-light pt-4 pb-4">

    <div class="container">
        <div class="row">
            <div class="col-lg-4   mb-lg-0 mb-3">
                <img src="{{image_baseUrl+'assets/images/products/Why-Choose-Our-Platform.jpg'}}"
                    class="img-fluid rounded-3">
            </div>
            <div class="col-lg-8 ">
                <h2>Why Choose Our Platform?</h2>
                <p>Our bus booking service is ideal for enterprises wanting to broaden their services with less
                    operational complexity. It allows for a multi-level distribution network, therefore, the agency
                    can grow by creating sub-agent networks and providing white-label solutions. This versatile
                    characteristic perfectly meets the demands of businesses, with the result of higher efficiency
                    and increased revenue.</p>

                <a routerLink="/account/auth/signup" class="btn btn-lg blue-btn">Register Now</a>
            </div>
        </div>

    </div>
</section>

<section class="home-section pt-5 pb-2 bg-white">
    <h2 class="text-center">Benefits for Your Business?</h2>
    <div class="container pt-4 pb-4">

        <div class="row ">
            <div class="col-lg-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <div align="center"><img src="{{image_baseUrl+'assets/images/products/Streamlined Operations.svg'}}"
                            height="80" class="mb-3">
                    </div>
                    <h5 class="fw-bold">Streamlined Operations</h5>
                    <p class="mb-0">Book all travels including bus, flights, and hotel rooms on a common platform
                        for access management.</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{image_baseUrl+'assets/images/products/Increased Revenue Potential.svg'}}" height="80"
                        class="mb-3">
                    <h5 class="fw-bold">Increased Revenue Potential</h5>
                    <p class="mb-0">Develop your network by means of sub-agents and various pricing strategies that
                        are adaptable.</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{image_baseUrl+'assets/images/products/Improved Client Satisfaction.svg'}}" height="80"
                        class="mb-3">
                    <h5 class="fw-bold">Improved Client Satisfaction</h5>
                    <p class="mb-0">Provide real-time options for booking and availability, thus ensuring an
                        uninterrupted experience for the end-users.</p>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{image_baseUrl+'assets/images/products/Reliable Technology.svg'}}" height="80"
                        class="mb-3">
                    <h5 class="fw-bold">Reliable Technology</h5>
                    <p class="mb-0">A secure, mobile-responsive platform that serves any size agency with the
                        durability and accessibility you need.</p>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="home-section pt-2 pb-2 blue-bg text-white text-center">

    <div class="container pt-4 pb-4">
        <div align="center"><img src="{{image_baseUrl+'assets/images/products/bus-book-offer.svg'}}" height="100"></div>
        <h2 class="text-white">What we can offer you?</h2>
        <p>The bus booking platform is among our global {{clientAbbr}} travel portfolio offerings, which are aimed at
            providing
            a competitive advantage to our customers through the inclusion of cutting edge features and functions.
            Whatever the size of your agency, be it the beehive or the complex organization, our platform will
            accommodate your needs and let your business flourish in a competitive travel world.</p>
        <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>

    </div>
</section>