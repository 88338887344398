<section class="inner-con pt-lg-4 pt-3">
   <div class="container">
      <div class="h5 mb-3">Terms and conditions</div>
       <div class="h6 blue-text fw-bold">1. Introduction</div>
       <p class="fst-italic">  This website (the “Site”) is owned and maintained by <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a>, represented by separate entities incorporated under the laws of the USA, Canada, India, UAE, and the UK. Each entity operates independently under the respective laws of its country. By using this website, you accept these terms and conditions. Sub-sites accessed through this Site may have their own terms, which must be reviewed and agreed to separately.   </p>
       <ul class="fst-italic">
          <li>Cancellation penalties or no-show fees are determined by the supplier and are non-refundable.</li>
           <li class="fw-bold mb-3">Cancellations must be made </li>
           <div class="table-responsive">
            <table width="100%" border="0" class="table table-bordered" cellspacing="0" cellpadding="0">
           <thead>
             <tr>
               <th>Country</th>
               <th>Email ID</th>
               <th>Submitted in between</th>
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>India</td>
               <td><a [href]="'mailto:' + refundInMail">{{refundInMail}}</a></td>
               <td>10 AM to 07:00 PM  IST</td>
             </tr>
             <tr>
               <td>USA</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  CST</td>
             </tr>
             <tr>
               <td>Canada</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  CST</td>
             </tr>
             <tr>
               <td>UK</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  GMT</td>
             </tr>
             <tr>
               <td>UAE</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  GST</td>
             </tr>
             </tbody>
           </table>

      </div>
      <li>Cancellations sent to any other address, or those submitted after 7:00 PM  or before 10:00 AM , will not be processed.  </li>
       </ul>
       <div class="h6 fw-bold">Processing Cancellations:</div>
       <ul class="fst-italic">
          <li>Cancellations in writing that are submitted by 7:00 p.m. on a business day will be handled the same day.</li>
           <li>Cancellations received on weekends or public holidays will be processed on the next business day. In urgent cases, the agent must contact the airline directly for cancellation. Any no-shows won't be our responsibility.</li>
       </ul>
       <div class="h6 fw-bold">Liability for Fees:</div>
          <ul class="fst-italic">
              <li>By making a booking, you accept responsibility for all cancellation fees imposed by both <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> and the supplier.</li>
           </ul>
       <div class="h6 fw-bold">Refunds:</div>
       <ul>
          <li class="fst-italic">Refunds, if applicable, will be subject to supplier and agency policies and may take a specified processing time.</li>
       </ul>
       <hr>
      <div class="h6 blue-text fw-bold mb-3">2. Website Usage</div>
      <div class="h6 fw-bold">Content Usage:</div>
          <ul class="fst-italic">
              <li>You may not distribute, modify, sell, or transmit any text, images, audio, or video content from this Site for commercial or public purposes without prior authorization.</li>
               <li>Unauthorized use of the content may violate copyright, trademark, privacy, or publicity laws and could result in civil or criminal penalties.</li>
           </ul>
       <div class="h6 fw-bold">Member Access:</div>
       <ul class="fst-italic">
              <li>Some sections of the Site require registration. You agree to register with correct and true information</li>
               <li>You are responsible for maintaining the privacy of your login credentials and all actions taken using your account.</li>
           </ul>
           <div class="h6 fw-bold">Copyright and Trademarks:</div>
       <ul class="fst-italic">
              <li>All content is protected by copyright laws, including text, software, audio, icons and images (collectively, the "Content").</li>
               <li>Any use of Content must comply with the guidelines provided by <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a>. Removal of copyright or trademark notices from downloaded content is prohibited.</li>
           </ul>
           <hr>
            <div class="h6 blue-text fw-bold mb-3">3. Deals and Discounts</div>
            <ul class="fst-italic">
               <li>Special deals, offers, or vouchers displayed on the Site are available for a limited time and may have additional terms and conditions.</li>
               <li>It is your responsibility to verify the availability and terms of any special offers. <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> reserves the right to modify or withdraw such offers at any time.</li>
            </ul>
            <hr>
            <div class="h6 blue-text fw-bold mb-3">4. Product and Service Availability</div>
            <ul class="fst-italic">
               <li>Products and services listed on the Site may not be available in all countries or regions.</li>
               <li>Please confirm the availability of specific products or services with an authorized <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> representative.</li>
            </ul>
            <hr>
            <div class="h6 blue-text fw-bold mb-3">5. Security and Payment Gateway</div>
            <ul class="fst-italic">
               <li><a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> employs necessary measures to maintain the security and integrity of its systems and your transactions.</li>
               <li>You agree to provide accurate credit or debit card information during payment and to use only cards that you are authorized to use.</li>
               <li>Fees, including exchange rates and transaction charges, are determined solely by your bank or payment provider. Any questions regarding such fees should be directed to your bank.</li>
              
            </ul>
            <hr>
            <div class="h6 blue-text fw-bold mb-3">6. Fees and Cancellations</div>
            <div class="h6 fw-bold">Cancellation Policies:</div>
            <ul >
               <li class="fst-italic">Cancellation penalties or no-show fees are determined by the supplier and are non-refundable.</li>
               <li class="fst-italic">Cancellations must be made </li>
              
               <div class="table-responsive">
            <table width="100%" border="0" class="table table-bordered" cellspacing="0" cellpadding="0">
           <thead>
             <tr>
               <th>Country</th>
               <th>Email ID</th>
               <th>Submitted in between</th>
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>India</td>
               <td><a [href]="'mailto:' + refundInMail">{{refundInMail}}</a></td>
               <td>10 AM to 07:00 PM  IST</td>
             </tr>
             <tr>
               <td>USA</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  CST</td>
             </tr>
             <tr>
               <td>Canada</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  CST</td>
             </tr>
             <tr>
               <td>UK</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  GMT</td>
             </tr>
             <tr>
               <td>UAE</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  GST</td>
             </tr>
             </tbody>
           </table>

      </div>
      <li class="fst-italic">Cancellations sent to any other address, or those submitted after 7:00 PM  or before 10:00 AM , will not be processed.  </li>
            </ul>
         <div class="h6 fw-bold">Processing Cancellations:</div>
          <ul class="fst-italic">
             <li>Written cancellations received by 7:00 p.m.  on a business day will be processed the same day.</li>
           <li>Cancellations received on weekends or public holidays will be processed on the next business day. In urgent cases, the agent must contact the airline directly for cancellation. We will not be responsible for any no-shows</li>
          </ul>
          <div class="h6 fw-bold">Liability for Fees:</div>
          <ul class="fst-italic">
             <li>By making a booking, you accept responsibility for all cancellation fees imposed by both <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> and the supplier.</li>
          </ul>
          <div class="h6 fw-bold">Refunds:</div>
          <p class="fst-italic">Refunds, if applicable, will be subject to supplier and agency policies and may take a specified processing time.</p>
          <hr>
             <div class="h6 blue-text fw-bold mb-3">7. Limitation of Liability</div>
             <ul class="fst-italic">
                <li><a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> serves as an intermediary between travel agencies and suppliers. It is not liable for any service deficiencies, cancellations, or issues related to products or services provided by third parties.</li>
               <li><a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> disclaims liability for any direct, indirect, or consequential damages arising from the use of this Site.</li>
             </ul>
             <hr>
              <div class="h6 blue-text fw-bold mb-3">8. Amendments to Terms</div>
              <ul class="fst-italic">
                    <li>The terms and conditions of {{websiteUrl|lowercase}} are subject to change at any time. By using the site going forward, you agree to the modified terms.</li>
              </ul>
              <hr>
               <div class="h6 blue-text fw-bold mb-3">9. Governing Law</div>
               <p class="fst-italic">These terms and conditions are governed by the laws of the USA, UK, India, UAE, and Canada and will be interpreted and enforced in accordance with the laws applicable in the respective country where the transaction or dispute arises.  </p>
               <p>Any disputes arising from the use of the Site will be resolved exclusively in the courts of the respective regions as follows: USA - Dallas, Canada - Alberta, India - Cochin, Kerala, UAE - Dubai, and UK - London.</p>
               <hr>
               <div class="h6 blue-text fw-bold mb-3">10. Contact Information</div>
               <p class="fst-italic fw-bold">If you have any questions about these terms and conditions, please contact:</p>
               <a [href]="liveUrl + '/static-pages/contact'" target="_blank">{{liveUrl}}/static-pages/contact
                </a>
               <p class="fst-italic mt-3">By using<a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a>, you confirm that you have read, understood, and agreed to these terms and conditions.</p>
   </div>
</section>