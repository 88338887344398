<!--Banner ssection starts here-->
<div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <h1 class="fb-h1">More Options, Better Prices, Happy Customers. <span>BTA | The Best {{clientAbbr}}
                        Flight Booking
                        Portal for Travel Agents</span></h1>

                <p>A {{clientAbbr}} flight booking portal offers travel agents unlimited global flights options to
                    effortlessly
                    cater to a wide variety of clients. With BTA, a leading {{clientAbbr}} travel agency, you access a
                    platform
                    that has endless possibilities to take your business toward its utmost success.</p>
                <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
            <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/flight-banner-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<!--Banner ssection ends here-->

<!--home-section starts here-->
<section class="home-section pt-4 pb-4">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center">


                <p>Customer care is the backbone of any successful travel business. It is a must that your clients be
                    satisfied and their journeys smooth. However, while so much depends on air travel, booking flights
                    can sometimes prove frustrating. More often than not, it involves visiting several airline websites,
                    complicated booking procedures, and sometimes even limited flight availability.</p>
                <p>Managing multiple reservations across different platforms quickly becomes overwhelming. As a travel
                    agent, you constantly have reservations to juggle, changes to make, and cancellations to attend to
                    for your customers. In the hectic travel business, relying on inaccurate information can lead to
                    lost opportunities and unhappy clients. Without proper support, it feels like an uphill battle</p>
                <p>But it doesn’t have to be like this! Associating with BTA, the {{clientAbbr}} flight booking portal,
                    will
                    completely change your agency experience. With BTA, you will have a dependable partner that boosts
                    your profits considerably while saving much of your time and effort.</p>
            </div>
            <div class="col-lg-5">
                <img src="{{imageUrl+'assets/images/products/travelagency.svg'}}" class="img-fluid">
            </div>
        </div>

    </div>
</section>
<!--home-section ends here-->
<!--home-section starts here-->
<section class="home-section bg-white">
    <div class="container pt-4 pb-4">
        <h2 class="text-center mb-3">How Our {{clientAbbr}} Flight Booking Portal Benefits You?</h2>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/User-Friendly-Interface.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">User-Friendly Interface</h5>
                    <p class="mb-0">Our platform has an easy-to-navigate interface and a booking process that is quick
                        and simple, thus saving time and improving operational efficiency within your business.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img src="{{imageUrl+'assets/images/products/search.svg'}}"
                        height="35"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Smart Search and Recommendations</h5>
                    <p class="mb-0">Personalize your hunt with parameters such as dates, airlines, and prices to have an
                        experience that is more tailored to your requirements.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/low-cost-flight.svg'}}" height="50">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Access to a Vast Low-Cost Flight Inventory</h5>
                    <p class="mb-0">Discover thousands of inexpensive flights from numerous airlines and routes across
                        the globe, making certain you get the best for your clients.</p>
                </div>
            </div>
        </div>

        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/inventory.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Real-Time Inventory Management & Automated Booking</h5>
                    <p class="mb-0">Get real-time availability and pricing on flights instantly, so you can decide
                        quickly and with confidence.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/dynamic-pricing.svg'}}" height="60">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Dynamic Pricing, Markup Control & Exclusive Deals</h5>
                    <p class="mb-0">Take advantage of competitive prices and exclusive offers that will not only help
                        you to increase your income but also allow you to offer cost-efficient solutions for your
                        customers.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!--home-section ends here-->

<!--home-section starts here-->
<section class="home-section bg-light pt-5 pb-5">
    <h3 class="fw-bold text-center mb-5">How Does Our {{clientAbbr}} Flight Booking Portal Work?</h3>
    <div class="container">
        <div class="row mb-lg-4">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Sign-Up-user.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Sign Up</h5>
                    <p class="mb-0">Visit our top {{clientAbbr}} flight booking website for travel agents, and hit the
                        "Sign Up"
                        button.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/fill-details.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Fill in Your Details</h5>
                    <p class="mb-0">In this regard, provide all your necessary information in the boxes provided. This
                        should include your agency information, contact details as well as any other information that
                        might be required.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/airplane.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Explore 600+ Airline Services</h5>
                    <p class="mb-0">Once registered, you access a huge database of more than 600 airline services. Check
                        the available options to find the best for your clients.</p>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/searching.svg'}}" height="80" class="mb-3">
                    <h5 class="fw-bold">Effortless Search</h5>
                    <p class="mb-0">Use the search functionality that is intuitive to the user by inputting distinctive
                        details like destination, dates, and preferences. This help it become easy to get the most
                        appropriate flights for your customers. </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/online-flight-booking.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Make a Purchase</h5>
                    <p class="mb-0">After identifying the perfect flights, proceed to purchase through our portal. The
                        booking process is made for your convenience.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/commission.svg'}}" height="80" class="mb-3">
                    <h5 class="fw-bold">Instant Commission</h5>
                    <p class="mb-0">Enjoy immense benefits! Your earned commission will be directly adjusted to your
                        statement after the purchase is confirmed. No hassle, just straightforward earnings.</p>
                </div>
            </div>

        </div>





    </div>
</section>
<!--home-section ends here-->

<!--home-section starts here-->
<section class="home-section pt-5 pb-2 bg-white">
    <h2 class="text-center">Why Is {{clientAbbr}} Flight Booking Portal Best for Travel Agents?</h2>
    <div class="container pt-4 pb-4">
        <p class="text-center">All our luxurious features make our {{clientAbbr}} flight booking portal the perfect
            option for
            travel agents. It's a mix of unique benefits, professional assistance, global presence, and high-end
            technology that will take an agency to another level.</p>
        <div class="row ">
            <div class="col-lg-4">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <div align="center"><img src="{{imageUrl+'assets/images/products/special-fare.svg'}}" height="80"
                            class="mb-3"></div>
                    <h5 class="fw-bold">Special Fare for Travel Agent</h5>
                    <p class="mb-0">Travel Agents get discounted flight fares and it helps them to provide
                        cost-effective options to their clients.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{imageUrl+'assets/images/products/agent-commission.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Agent Commission</h5>
                    <p class="mb-0">Our flight booking system works on a commission and it adds an extra revenue stream
                        for travel agents.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{imageUrl+'assets/images/products/travel-experts.svg'}}" height="80" class="mb-3">
                    <h5 class="fw-bold">Team Expertise</h5>
                    <p class="mb-0">Our professionals are experts in the travel industry. Thus, they provide valuable
                        insights and support a travel agent throughout the journey.</p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-lg-0 mb-4">
                    <img src="{{imageUrl+'assets/images/products/global-presence.svg'}}" height="80" class="mb-3">
                    <h5 class="fw-bold">Global Presence</h5>
                    <p class="mb-0">We have an international network of flights and services. That would give you a
                        wider reach and more offerings. </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-lg-0 mb-4">
                    <img src="{{imageUrl+'assets/images/products/24-7-support.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">24*7 Professional Support</h5>
                    <p class="mb-0">We ensure assistance whenever needed and it enhances the overall experience of a
                        travel agent. In turn, easing up their clients’ travel stress.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-lg-0 mb-4">
                    <img src="{{imageUrl+'assets/images/products/Booking-Reliability.svg'}}" height="80" class="mb-3">
                    <h5 class="fw-bold">Booking Reliability</h5>
                    <p class="mb-0">Our {{clientAbbr}} ticketing portal is a trustworthy platform that ensures the
                        reliability of
                        bookings. It minimizes errors and provides confidence in the flight booking service.</p>
                </div>
            </div>

        </div>
    </div>
</section>
<!--home-section ends here-->

<!--home-section starts here-->
<section class="home-section pt-5 pb-5 bg-light">
    <div class="container">
        <h2 class="text-center">
            FAQ</h2>
        <h2>
        </h2>
        <h3 class="text-center fw-bold"> Your Queries Will Take a Flight Here</h3>
        <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item" *ngFor="let item of accordionData; let i = index">
                <h2 class="accordion-header" [id]="item.headingId">
                    <button class="accordion-button shadow-none fw-bold" [class.collapsed]="!item.expanded"
                        type="button" (click)="toggleAccordion(i)" [attr.aria-expanded]="item.expanded"
                        [attr.aria-controls]="item.id">
                        {{ item.title }}
                    </button>
                </h2>
                <div class="accordion-collapse collapse" [class.show]="item.expanded" [id]="item.id"
                    [attr.aria-labelledby]="item.headingId" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        {{ item.content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>