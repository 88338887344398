   <!-- header ends here-->
   <div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">	
                <h1 class="fb-h1 mb-3">Launch TMC Whitelabel: A Travel Booking Platform to Call Yours</h1>
              <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
             <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/whitelabel.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<section class="pt-4 pb-4">
	<div class="container">
    <div class="row">
    	<div class="col-lg-4 order-lg-0 order-1"><img src="{{imageUrl+'assets/images/products/wl-1.jpg'}}" class="img-fluid rounded-5 mb-lg-3 mb-3"></div>
        <div class="col-lg-8 order-lg-1 order-0">
        	<p>The travel industry is characterized by a fierce competition wherein agencies are always on the lookout to innovate and provide more value to their clients. Building an entirely bespoke, fully-functioning booking platform from the ground up can be prohibitively expensive and time-consuming for even small to medium-sized travel businesses. This is where a white-label travel solution (such as TMC Whitelabel) emerges with cost-effective and powerful potential.</p>
            <h2>What is TMC Whitelabel?</h2>
            <p>TMC Whitelabel is a dedicated white-label OTAs solution for travel agents. It provides a completely tailored, out of the box online booking engine. It means that your agency can provide all the needed booking services — flights, hotels and even car rentals and activities take place under your own brand. So, agencies can provide clients with a fully branded experience with no dev effort by using this solution.</p>
               <p>TMC Whitelabel offers a powerful solution in the form of a travel booking engine that is already integrated into your website. It would contain the nut-and-bolts functionalities that clients look for, a complete one-stop shop.</p>
    <p> This not only saves time and resources but positions your agency as a modern, tech-savvy provider capable of competing with the heavyweights.</p>
        </div>
    </div>
</div> 
 <section class="pt-4 pb-4 bg-light mb-3">
 	<div class="container">
    	<div class="h3 text-center mb-4">Importance and Functions of TMC Whitelabel</div>
        <div class="row fw-bold">
        	<div class="col-lg-5">
            		<div class="row">
                    <div class="col-lg-4 col-12">
            	<div class="border rounded-3 bg-white p-3  text-center">
                	<div align="center" class="mb-3"><img src="{{imageUrl+'assets/images/products/All-in-one-Reservation-Features.png'}}" height="50"></div>
                	All-in-one Reservation Features
                </div>
            </div>
            <div class="col-lg-4 col-12">
            	<div class="border rounded-3 bg-white p-3 text-center">
                <div align="center" class="mb-3"><img src="{{imageUrl+'assets/images/products/CustomizedBranding.png'}}" height="50"></div>
                	Customized Branding
                </div>
            </div>
             <div class="col-lg-4 col-12"> 
            	<div class="border rounded-3 bg-white p-3 text-center">
                <div align="center" class="mb-3"><img src="{{imageUrl+'assets/images/products/StreamlinedUserExperience.png'}}" height="50"></div>
                	Streamlined User Experience
                </div>
            </div>
                    </div>
            </div>
            <div class="col-lg-2">
            	<div class="border rounded-3 bg-white p-3 text-center">
                <div align="center" class="mb-3"><img src="{{imageUrl+'assets/images/products/NewOpportunitiestoIncreaseRevenue.png'}}" height="50"></div>
                	New Opportunities to Increase Revenue
                </div>
            </div>
            <div class="col-lg-5">
            	<div class="row">
                	 <div class="col-lg-4 col-12">
            	<div class="border rounded-3 bg-white p-3 text-center">
                 <div align="center" class="mb-3"><img src="{{imageUrl+'assets/images/products/Cutting-EdgeTechnology.png'}}" height="50"></div>
                	Cutting-Edge Technology
                </div>
            </div>
            <div class="col-lg-4 col-12">
            	<div class="border rounded-3 bg-white p-3 text-center">
                <div align="center" class="mb-3"><img src="{{imageUrl+'assets/images/products/Cost-EffectiveSolution.png'}}" height="50"></div>
                	Cost-Effective Solution
                </div>
            </div>
            <div class="col-lg-4 col-12">
            	<div class="border rounded-3 bg-white p-3 text-center">
                 <div align="center" class="mb-3"><img src="{{imageUrl+'assets/images/products/FastTimetoMarket.png'}}" height="50"></div>
                	Fast Time to Market
                </div>
            </div>
                </div>
            </div>
        </div> 
        </div>
 </section>   
    <section class="pt-3 pb-3">
    	<div class="container">
        	 <div class="h3 text-center">The Advantage of Whitelabel TMC For Travel Agents</div>
              <p class="text-center">Today, travel agencies have strong competitors in the face of giants like AirBnB or new startups. It has never been more important to shine with a convenient, quality booking experience among the noise. This is where TMC White Label comes into play as a perfect solution in offering a white label platform that provides functionalities of major booking websites under the familiar experience of your brand.</p>
              <p class="text-center">Now with TMC Whitelabel, your agency can provide a robust travel booking engine at scale without the expense, time and tech headache of )st building one. TMC Whitelabel helps you add services, enhance your brand or increase revenue faster and more easily.</p>
        </div>
    </section>
   <div class="container">
	 <div class="alert alert-info pt-4 pb-4">
        	<div class="mb-3" align="center"><img src="{{imageUrl+'assets/images/products/whitelabel.svg'}}" height="112"></div>
        	 <div class="h3 text-center">Get Started with TMC Whitelabel Today</div>
              <p class="text-center">TMC Whitelabel is not just a booking engine but an all-in-one solution that you can use for your travel agency to provide quality services in your own name. The advantages are, after all, evident: from boosting revenues to customer loyalty. Enjoy the future of travel booking and bring your agency to the next level with a platform built for any traveler.</p>
    <p class="text-center">Contact us today to find out how TMC Whitelabel can be customized for your agency and allow you to start providing an experience that matches the quality of your business.</p>
    <div class="text-center"><a routerLink="/static-pages/contact" class="btn btn-lg bnr-reg-btn">Contact Now</a></div>
        </div>
   </div>
</section>