<div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <h1 class="fb-h1 text-white">Highly reliable and efficient taxi services through {{clientAbbr}} travel
                    agency.</h1>

                <p>When it comes to seamless travel, transportation plays a key role in delivering a memorable
                    experience. At {{clientAbbr}} Travel Agency, we take our knowledge of flights and hotels beyond to
                    include
                    comprehensive cab booking services to ensure our clients have a consistent solution - reliable,
                    worry free, ground transportation. Whatever for business trips, leisure vacations or airport
                    transfers, cab services are provided with an individual approach taking into consideration the
                    needs of travelling professionals and their clients.</p>
                <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
            <div class="col-lg-6">
                <img src="{{image_baseUrl+'assets/images/products/taxi-service.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<!--Banner ssection ends here-->

<section class="home-section bg-white">
    <div class="container pt-4 pb-0">
        <h2 class="text-center mb-3">Why Choose {{clientAbbr}} Travel Agency for Cab Bookings?</h2>

        <div class="row">
            <div class="col-lg-4 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Extensive-Global-Network.svg'}}" height="80">
                    </div>
                    <div class="text-center">
                        <h5 class="fw-bold">Extensive Global Network</h5>
                        <p>Having access to a global network of reliable cab firms, we guarantee the best travel
                            experience in many cities and locations around the world. From local rides to intercity
                            journeys, we’ve got you covered.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Diverse-Fleet-Options.svg'}}" height="80">
                    </div>
                    <div class="text-center">
                        <h5 class="fw-bold">Diverse Fleet Options</h5>
                        <p>Select from a variety of motor vehicles such as sedans, SUVs, luxury automobiles or
                            economy cars. Whether it’s a budget-friendly trip or a high-end business requirement, we
                            have the perfect ride for every occasion.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Real-Time-cab.svg'}}" height="80"></div>
                    <div class="text-center">
                        <h5 class="fw-bold">Real-Time Availability & Instant Confirmation</h5>
                        <p>Our state-of-the art platform provides real-time availability of cabs and confirms
                            instant booking confirmations easily allowing you to book rides efficiently even in peak
                            hours.</p>
                    </div>
                </div>
            </div>

        </div>


        <div class="row">
            <div class="col-lg-4 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Safe-Professional-Drivers.svg'}}" height="80">
                    </div>
                    <div class="text-center">
                        <h5 class="fw-bold">Safe & Professional Drivers</h5>
                        <p>We prioritize safety and reliability. All of our car services are rendered by vetted,
                            professional drivers who guarantee your trip to be enjoyable and safe.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Affordable-Pricing.svg'}}" height="85">
                    </div>
                    <div class="text-center">
                        <h5 class="fw-bold">Affordable Pricing</h5>
                        <p>Transparent and competitive pricing with no hidden charges. Enjoy peace of mind knowing
                            your clients are getting great value for their transportation needs.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Customizable-Itineraries.svg'}}" height="80">
                    </div>
                    <div class="text-center">
                        <h5 class="fw-bold">Customizable Itineraries</h5>
                        <p>Ability to include custom itineraries that are aligned to the travel plan and time
                            constraints of the client (multiple stops, hourly rentals, round-trip bookings).</p>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/24-7-support.svg'}}" height="80"></div>
                    <div class="text-center">
                        <h5 class="fw-bold">24/7 Support</h5>
                        <p>Our customer support team is available around the clock to assist with booking queries,
                            changes, or emergencies.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="home-section pt-4 pb-4 bg-light mb-3">
    <div class="container">
        <h2 class="h2 text-center mb-4">Cab Services We Offer</h2>
        <div class="row g-3">
            <div class="col-lg-4 mb-3">
                <div class="rounded-4 bg-white p-3 shadow-sm">
                    <div class="rounded-3 overflow-hidden"><img
                            src="{{image_baseUrl+'assets/images/products/Airport-Transfers.jpg'}}" class="img-fluid">
                    </div>
                    <div class="h5 fw-bold blue-text text-center mt-3 mb-0">Airport Transfers</div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="rounded-4 bg-white p-3 shadow-sm">
                    <div class="rounded-3 overflow-hidden"><img
                            src="{{image_baseUrl+'assets/images/products/City-Tours.jpg'}}" class="img-fluid"></div>
                    <div class="h5 fw-bold blue-text text-center mt-3 mb-0">City Tours</div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="rounded-4 bg-white p-3 shadow-sm">
                    <div class="rounded-3 overflow-hidden"><img
                            src="{{image_baseUrl+'assets/images/products/Business-Travel.jpg'}}" class="img-fluid">
                    </div>
                    <div class="h5 fw-bold blue-text text-center mt-3 mb-0">Business Travel</div>
                </div>
            </div>
        </div>
        <div class="row g-3">
            <div class="col-lg-4 mb-3">
                <div class="rounded-4 bg-white p-3 shadow-sm">
                    <div class="rounded-3 overflow-hidden"><img
                            src="{{image_baseUrl+'assets/images/products/Long-Distance-Rides.jpg'}}" class="img-fluid">
                    </div>
                    <div class="h5 fw-bold blue-text text-center mt-3 mb-0">Long-Distance Rides</div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="rounded-4 bg-white p-3 shadow-sm">
                    <div class="rounded-3 overflow-hidden"><img
                            src="{{image_baseUrl+'assets/images/products/Hourly-Rentals.jpg'}}" class="img-fluid"></div>
                    <div class="h5 fw-bold blue-text text-center mt-3 mb-0">Hourly Rentals</div>
                </div>
            </div>
            <div class="col-lg-4 mb-3">
                <div class="rounded-4 bg-white p-3 shadow-sm">
                    <div class="rounded-3 overflow-hidden"><img
                            src="{{image_baseUrl+'assets/images/products/Luxury-Car-Rentals.jpg'}}" class="img-fluid">
                    </div>
                    <div class="h5 fw-bold blue-text text-center mt-3 mb-0">Luxury Car Rentals</div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-section pt-3 pb-3">
    <h3 class="fw-bold text-center mb-4">How it works?</h3>
    <div class="container">
        <div class="row mb-lg-4">
            <div class="col-lg-3 mb-lg-0 mb-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/Search-Select.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Search & Select</h5>
                    <p class="mb-0">Conveniently query for and choose the desired cab type and service to meet the
                        travelling requirements of the client.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-lg-0 mb-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/Book -Confirm.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Book & Confirm</h5>
                    <p class="mb-0">By a few clicks, lock up the booking and get it on the spot.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-lg-0 mb-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/Enjoy Seamless Travel.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Enjoy Seamless Travel</h5>
                    <p class="mb-0">Our expert drivers guarantee a top class travelling experience from the pick-up
                        to the drop-off.</p>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{image_baseUrl+'assets/images/products/24-7 Assistance.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">24/7 Assistance</h5>
                    <p class="mb-0">Need modifications or have questions? Our support team is just a call away.</p>
                </div>
            </div>

        </div>

    </div>
</section>

<section class="home-section pt-5 pb-2 bg-light">
    <h2 class="text-center">Benefits for Travel Partners</h2>
    <div class="container pt-4 pb-4">

        <div class="row g-2">
            <div class="col-lg-3 mb-2">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4 h-100">
                    <div align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Integrated-Solutions-for-Travel-Agencies.svg'}}"
                            height="100" class="mb-3"></div>
                    <h5 class="fw-bold">Integrated Solutions for Travel Agencies</h5>
                    <p class="mb-0">Combine cab bookings with flights, hotels, and holiday packages to offer your
                        clients a complete travel solution.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-2">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4 h-100">
                    <img src="{{image_baseUrl+'assets/images/products/Local-Currency-Transactions.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Local Currency Transactions</h5>
                    <p class="mb-0">Easy local currency payment options, so transactions are effortless for both
                        your agency and your clients.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-2">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4 h-100">
                    <img src="{{image_baseUrl+'assets/images/products/Competitive-Pricing.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Earn More with Competitive Pricing</h5>
                    <p class="mb-0">Increase the profit of your company using our affordable prices and our
                        commissionable services.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-2">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-lg-0 mb-4 h-100">
                    <img src="{{image_baseUrl+'assets/images/products/Global-Service-Delivery.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Global Service Delivery</h5>
                    <p class="mb-0">At major global airports, smaller cities, and everywhere in between, our cab
                        services are available at many locations around the world. </p>
                </div>
            </div>


        </div>

    </div>
</section>
<section class="home-section pt-5 pb-2 bg-white">

    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center">
                <h2>Simplify Ground Transportation with {{clientAbbr}} Travel Agency</h2>
                <p>The role of reliable transport in delivering first class travel can be underestimated at a
                    {{clientAbbr}}
                    Travel Agency. Our cab services are designed to ensure seamless connectivity, comfort, and
                    safety for your clients, whether they’re exploring a city, heading to the airport, or traveling
                    between destinations.</p>
            </div>
            <div class="col-lg-5">
                <img src="{{image_baseUrl+'assets/images/products/Simplify-Ground-Transportation.svg'}}"
                    class="img-fluid">
            </div>
        </div>
    </div>
</section>
<section class="home-section pt-2 pb-2 blue-bg text-white text-center">

    <div class="container pt-4 pb-4">
        <div align="center"><img src="{{image_baseUrl+'assets/images/products/cab-icon.svg'}}" height="100"></div>
        <h2 class="text-white">Get Started Today!</h2>
        <p>Partner with us and unlock a world of efficient cab solutions tailored to your business and your clients'
            needs.</p>
        <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
    </div>
</section>