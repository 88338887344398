<div class=" pt-5 pb-5">
    <div class="container">
        <div class="h2 fw-bolder"><span class="blue-text">Business Travel</span><br> Made Simple, Efficient, and
            Rewarding
        </div>
        <div class="container biztrav-img"><img src="../../../assets/images/home/Business-Travel.svg" class="img-fluid"> </div>

        <p class="lead">Welcome to B2btravelagency.com, where corporate travel is reimagined to meet the needs of modern
            businesses. We don’t just book flights—we revolutionize the way your company travels, delivering simplicity,
            efficiency, and exceptional value every step of the way.. </p>
        <p>With access to exclusive corporate deals, 24/7 personalized support, and tailored solutions, we ensure your
            travel plans are as dynamic as your business. Whether it’s a last-minute trip or a long-term partnership,
            our all-in-one platform streamlines every step, saving you time and maximizing value.</p>
        <div class="h4 fw-bold">Ready to redefine your business travel? Let us elevate your journey.</div>
        <a routerLink="/account/auth/signup" class="btn btn-lg blue-btn text-white mt-4">Register Now</a>

    </div>
</div>