<!--Banner ssection starts here-->
<div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center">
                <h1 class="fb-h1 text-white">Explore Europe with Eurail – Seamless Train Travel for Every Journey</h1>

                <p>Eurail services from {{clientAbbr}} Travel Agency provide travel agents with unmatched ease and
                    flexibility to let clients access Europe´s extensive rail network. Whether zooming at bullet train
                    speeds or taking slower tracks on the local lines, Eurail passes let you travel through countries
                    with a single fare making for some amazing experiences across varied terrains and urban centers.</p>
                <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
            <div class="col-lg-5">
                <img src="{{image_baseUrl+'assets/images/products/eurail-banner-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<!--Banner ssection ends here-->


<section class="home-section bg-white">
    <div class="container pt-4 pb-0">
        <h2 class="text-center mb-3">Why Choose Eurail Passes?</h2>

        <div class="row">
            <div class="col-lg-3 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Unlimited-Travel-Options.svg'}}" height="80">
                    </div>
                    <div class="text-center">
                        <h5 class="fw-bold">Unlimited Travel Options</h5>
                        <p>Eurail allows travel to over 30 countries throughout Europe, providing travelers with the
                            ability to hop on and off anywhere at will.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Flexible-Plans.svg'}}" height="80"></div>
                    <div class="text-center">
                        <h5 class="fw-bold">Flexible Plans</h5>
                        <p>Select among Global Passes for various countries or Single-Country Passes adjusted to
                            specific places.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Ultra-Convenient-Booking-Process.svg'}}"
                            height="80"></div>
                    <div class="text-center">
                        <h5 class="fw-bold">Ultra-Convenient Booking Process</h5>
                        <p>Our {{clientAbbr}} portal allows agents to effortlessly book with real-time schedules, seat
                            reservations, and travel insights.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 mb-4">
                <div class="border rounded-4 mb-2 p-3 h-100">
                    <div class="mb-3" align="center"><img
                            src="{{image_baseUrl+'assets/images/products/Agent-Only-Deals.svg'}}" height="80"></div>
                    <div class="text-center">
                        <h5 class="fw-bold">Agent-Only Deals</h5>
                        <p> Access exclusive rates and discounts that increase your client offerings while raising
                            agency profit margins.</p>
                    </div>
                </div>
            </div>

        </div>


    </div>
</section>
<section class="home-section bg-light">
    <div class="container pt-4 pb-4">
        <h2 class="text-center mb-3">Features and Benefits</h2>
        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/Comprehensive-Network.svg'}}" height="50">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Comprehensive Network</h5>
                    <p class="mb-0">Across 40,000 destinations in Europe—from the biggest of cities to the tiniest
                        hidden gems, spanning the most iconic landscapes—Eurail connects you.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/User-Friendly-Passes.svg'}}" height="55">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">User-Friendly Passes</h5>
                    <p class="mb-0">Flexible travel days, routes and reserved seats allow clients to fit their journeys
                        into a customized itinerary. Passes can be customized that are valid for a few days or several
                        months.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/Smart-Travel-Tools.svg'}}" height="50">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Smart Travel Tools</h5>
                    <p class="mb-0">With features like interactive maps, mobile tickets and real-time updates, exploring
                        Europe has never been easier and more efficient.</p>
                </div>
            </div>
        </div>

        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/Travel-in-Comfort.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Travel in Comfort</h5>
                    <p>Eurail Pass holders get to experience the top-notch modern railway system with high-speed and
                        picturesque trains where comfort and speed are core values.</p>
                    <p class="mb-0">While the latter has entered and developed a business model around multi-country
                        travel through complementary EU open-sky treaties, Eurail works with {{clientAbbr}} Travel
                        agent.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/Select-the-Right-Pass.svg'}}" height="60">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Select the Right Pass</h5>
                    <p class="mb-0">Whether clients are looking for a weekend escape or a summer full of travel, there
                        are several Eurail Pass forms available and {{clientAbbr}} Travel Agency lets agents select the
                        correct pass.</p>
                </div>
            </div>
        </div>

        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/Immediate-Booking-Confirmation.svg'}}"
                        height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Immediate Booking, and Confirmation.</h5>
                    <p class="mb-0">Book and confirm your Eurail Passes in just a few clicks through our portal. Booking
                        Forms: Simplistic seat acquisition and applicable real-time availability</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/Seamless-Ticketing-Process.svg'}}"
                        height="60"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Seamless Ticketing Process</h5>
                    <p class="mb-0">Send mobile tickets to your clients directly and they can forget about ticket
                        counters and start traveling right away with the help of agents.</p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3 bg-white">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{image_baseUrl+'assets/images/products/eurail/Dedicated-Support.svg'}}" height="60"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Dedicated Support</h5>
                    <p class="mb-0">We offer agents 24/7 support in selecting a pass, planning itineraries, and making
                        any changes needed.</p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="home-section pt-5 pb-2 bg-white">
    <h2 class="text-center mb-0">Why {{clientAbbr}} Travel Agency for Proveneur Eurail Passes?</h2>
    <div class="container pt-4 pb-4">
        <p class="text-center">{{clientAbbr}} Travel Agency offers travel agent and customer all feature with our
            powerful platform.</p>
        <div class="row g-2">
            <div class="col-lg-3 mb-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4 h-100">
                    <div align="center"><img
                            src="{{image_baseUrl+'assets/images/products/eurail/Competitive-Pricing-euro.svg'}}"
                            height="100" class="mb-3"></div>
                    <h5 class="fw-bold">Competitive Pricing</h5>
                    <p class="mb-0">Get hundreds of deals at dealer pricing and competitive rates that deliver value to
                        both clients and agents.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4 h-100">
                    <img src="{{image_baseUrl+'assets/images/products/eurail/Integrated-Payment-Options.svg'}}"
                        height="100" class="mb-3">
                    <h5 class="fw-bold">Integrated Payment Options</h5>
                    <p class="mb-0">With international agents in the mix, lowering barriers with multiple currency
                        support simplifies transactions.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4 h-100">
                    <img src="{{image_baseUrl+'assets/images/products/eurail/Trusted-Partnership.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Trusted Partnership</h5>
                    <p class="mb-0">Having worked together for many years in the travel space, we have built a stable
                        and trustworthy platform with decades of expertise behind it.</p>
                </div>
            </div>
            <div class="col-lg-3 mb-3">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-lg-0 mb-4 h-100">
                    <img src="{{image_baseUrl+'assets/images/products/eurail/Advanced-Technology-eurail.svg'}}"
                        height="80" class="mb-3">
                    <h5 class="fw-bold">Advanced Technology</h5>
                    <p class="mb-0">The intelligent search features of our booking portal, its ease in making changes,
                        and a mobile-first design assist agents build remarkable European journeys. </p>
                </div>
            </div>

        </div>


    </div>
</section>

<section class="home-section pt-2 pb-2 bg-light">

    <div class="container pt-4 pb-4">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <h2>Help Your Clients Travel to Europe Starting Today</h2>
                <p>Eurail will open up Europe to you, traveling from country to country should be as simple as possible.
                    Sign up with {{clientAbbr}} Travel Agency and discover unlimited possibilities to connect clients
                    with the best rail in Europe.</p>

                <a routerLink="/account/auth/signup" class="btn btn-lg blue-btn w-50 mb-3">Register Now</a>
            </div>
            <div class="col-lg-6  mb-lg-0 mb-3">
                <img src="{{image_baseUrl+'assets/images/products/eurail/eurail-travel.jpg'}}"
                    class="img-fluid rounded-3">
            </div>

        </div>




    </div>
</section>