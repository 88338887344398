import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-explore-tmc',
  templateUrl: './explore-tmc.component.html',
  styleUrl: './explore-tmc.component.scss'
})
export class ExploreTmcComponent {
  public imageUrl = environment?.imageUrl;

}
