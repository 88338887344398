import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent {
  public imageUrl = environment?.imageUrl;
  public clientAbbr = environment?.clientAbbr;
  constructor(
    private router: Router
  ) { }
}
