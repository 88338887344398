<section class="inner-con pt-lg-4 pt-3">
   <div class="container">
      <div class="h5 mb-3">Privacy Policy</div>
      <div class="h6 blue-text fw-bold">1. General Communication Policy</div>
      <p class="fst-italic">All communications, including comments, data, questions, suggestions, or other materials you
         transmit to the site by email or other means, will be considered non-confidential. By submitting any material,
         you agree: </p>
      <ul class="fst-italic">
         <li>{{clientAbbr}} Travel Agency may use this material without violating any moral rights, privacy rights,
            proprietary or ownership rights, publicity rights, or credit rights.</li>
         <li>No approval is necessary for the utilization of this material.</li>
      </ul>
      <hr>
      <div class="h6 blue-text fw-bold">2. Usage of Submitted Materials</div>
      <p class="fst-italic">By submitting materials to this site, you grant {{clientAbbr}} Travel Agency a perpetual,
         worldwide, irrevocable, non-exclusive license to:</p>
      <ul class="fst-italic">
         <li>Adapt, broadcast, transform, duplicate, disseminate, license, perform, display, publish, sell, and transmit
            such materials in any medium.</li>
      </ul>
      <hr>
      <div class="h6 blue-text fw-bold">3. Security of Communications</div>
      <p class="fst-italic">Messages or information sent to this site may be intercepted or read by unauthorized parties
         unless encrypted. {{clientAbbr}} Travel Agency is not responsible for unauthorized access or interception of
         communications.</p>
      <hr>
      <div class="h6 blue-text fw-bold">4. Site Accuracy and Updates</div>
      <p class="fst-italic">The site may contain typographical errors or inaccuracies. The {{clientAbbr}} Travel Agency
         retains the authority to modify or enhance the website at any time without prior notification.</p>
      <hr>
      <div class="h6 blue-text fw-bold">5. Disclaimer of Warranties</div>
      <p class="fst-italic">All content provided on the website is made available "As Is," without any warranties or
         assurances of any kind. {{clientAbbr}} Travel Agency disclaims all warranties, express or implied, including
         but not limited to:</p>
      <ul class="fst-italic">
         <li>Implied guarantees of suitability or functionality for a specific purpose.</li>
         <li>Accuracy or completeness of displayed materials such as hotel images. The hotel chain or individual
            facility is ultimately in charge of accuracy.</li>
      </ul>
      <hr>
      <div class="h6 blue-text fw-bold">6. Limitation of Liability</div>
      <p class="fst-italic">{{clientAbbr}} Travel Agency is a service platform provider and does not serve as a direct
         operator or provider of transportation, travel, tour, or hotel services. Therefore:</p>
      <ul>
         <li>{{clientAbbr}} Travel Agency is not liable for any inadequacies, deprivations, or deficiencies in services
            booked through its platform.</li>
         <li>The agency shall not be held liable for any direct, indirect, incidental, punitive, or consequential
            damages resulting from the use of this website</li>
      </ul>
      <hr>
      <div class="h6 blue-text fw-bold">7. Indemnity</div>
      <p class="fst-italic">You agree to indemnify and hold harmless {{clientAbbr}} Travel Agency and its affiliates
         from any claims, losses, liabilities, or damages arising out of your use of the site or any materials provided.
      </p>
      <hr>
      <div class="h6 blue-text fw-bold">8. Contact Information</div>
      <p class="fst-italic">For any enquiries or concerns regarding our privacy statement, please get in touch with:</p>
      <p><strong>Email:</strong> <a [href]="'mailto:' + clientEmail">{{clientEmail}}</a></p>
      <p><strong>Phone:</strong> <a href="tel:02269693333">022-69693333</a></p>
      <p class="mb-1"><strong>Address:</strong></p>
      <p class="fst-italic">{{clientAbbr}} Travel Agency India Pvt Ltd<br>
         A803, 8th Floor BSEL Tech Park, Sector 30A Vashi, Navi Mumbai 400705<br>

      </p>
      <p class="fw-bold fst-italic">You fully consent to our privacy statement by using this website. If you don't
         agree, please discontinue using the website immediately </p>

   </div>
</section>