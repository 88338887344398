<div class="home-banner pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/flight-banner-img.svg'}}" class="img-fluid">
            </div>
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <div class="h6 bg-warning mb-1 fw-bold w-25 p-2 rounded-5 text-center">FLIGHT</div>
                <h1 class="fb-h1">More Options, Better Prices, Happy Customers. <span>BTA | The Best {{clientAbbr}}
                        Flight
                        Booking Portal for Travel Agents</span></h1>

                <p>A {{clientAbbr}} flight booking portal offers travel agents unlimited global flights options to
                    effortlessly
                    cater to a wide variety of clients. With BTA, a leading {{clientAbbr}} travel agency, you access a
                    platform
                    that has endless possibilities to take your business toward its utmost success.</p>
                <a routerLink="/static-pages/flights" class="btn btn-lg bnr-reg-btn">EXPLORE</a>
            </div>

        </div>
    </div>
</div>
<div class="home-banner bg-light pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center order-lg-0 order-1">
                <div class="h6 bg-warning mb-1 fw-bold w-25 p-2 rounded-5 text-center">HOTEL</div>
                <h1 class="text-black">{{clientAbbr}} Online Hotel Booking Got Simpler</h1>

                <p class="text-black">
                    Log in to our travel agency {{clientAbbr}} hotel booking system to get massive hotel information at
                    your
                    fingertips. It is reliable, secure, and 24/7 availability.
                </p>
                <a routerLink="/static-pages/hotel" class="btn btn-lg bnr-reg-btn border shadow">EXPLORE</a>
            </div>
            <div class="col-lg-5 order-lg-1 order-0">
                <img src="{{imageUrl+'assets/images/products/hotel-banner.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="home-banner pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/holiday-banner.svg'}}" class="img-fluid">
            </div>
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <div class="h6 bg-warning mb-1 fw-bold w-25 p-2 rounded-5 text-center">HOLIDAYS</div>
                <h1>Easy {{clientAbbr}} Holiday Bookings</h1>

                <p>
                    Log into our vacation booking portal as a travel agent to get easy access to an international
                    variety of holiday packages. Use our secure, reliable service and maximize your productivity and
                    profitability since our portal is available 24/7.
                </p>
                <a routerLink="/static-pages/holidays" class="btn btn-lg bnr-reg-btn">EXPLORE</a>
            </div>

        </div>
    </div>
</div>
<div class="home-banner bg-light ">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <div class="h6 bg-warning mb-1 fw-bold w-25 p-2 rounded-5 text-center">VISA</div>
                <h1 class="fb-h1 text-black">Visa services get easier with B2B Travel Agency</h1>
                <div class="h4 text-black">Unlock international travel with ease</div>

                <p class="text-black">Applying for a visa can sometimes be rather daunting. But iB2B Travel Agency is
                    there to facilitate visa arrangements for travel agents, clients, and corporations for easy access
                    to global destinations. We work with a wide range of visas, from tourist to business and work
                    permits, for over 100 countries.</p>
                <a routerLink="/static-pages/visa" class="btn btn-lg bnr-reg-btn shadow">EXPLORE</a>
            </div>
            <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/visa-banner-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="home-banner pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center order-lg-0 order-1">
                <div class="h6 bg-warning mb-1 fw-bold w-25 p-2 rounded-5 text-center">BUS</div>
                <h1 class="fb-h1">{{clientAbbr}} Bus Booking Services for Travel Agencies</h1>

                <p>{{clientAbbr}}TravelAgency.com offers a robust bus booking platform that is convenient for travel
                    agencies and corporate clients. Our well-thought-out system, on the other hand, allows company
                    clients to see and handle the assets in real-time, while it is also a part of the complete travel
                    solution package that connects with other essential services such as flights, hotels, and packages.
                </p>
                <a routerLink="/static-pages/bus" class="btn btn-lg bnr-reg-btn border">EXPLORE</a>
            </div>
            <div class="col-lg-5 order-lg-1 order-0">
                <img src="{{imageUrl+'assets/images/products/bus-banner-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<div class="home-banner bg-light pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/taxi-service.svg'}}" class="img-fluid">
            </div>
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <div class="h6 bg-warning mb-1 fw-bold w-25 p-2 rounded-5 text-center">CAB</div>
                <h1 class="fb-h1 text-black">Highly reliable and efficient taxi services through {{clientAbbr}} travel
                    agency.</h1>

                <p class="text-black">When it comes to seamless travel, transportation plays a key role in delivering a
                    memorable experience. At {{clientAbbr}} Travel Agency, we take our knowledge of flights and hotels
                    beyond to include comprehensive cab booking services to ensure our clients have a consistent
                    solution - reliable, worry free, ground transportation. Whatever for business trips, leisure
                    vacations or airport transfers, cab services are provided with an individual approach taking into
                    consideration the needs of travelling professionals and their clients.</p>
                <a routerLink="/static-pages/cab" class="btn btn-lg bnr-reg-btn shadow">EXPLORE</a>
            </div>

        </div>
    </div>
</div>

<div class="home-banner pb-3">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center order-lg-0 order-1">
                <div class="h6 bg-warning mb-1 fw-bold w-25 p-2 rounded-5 text-center">EURAIL</div>
                <h1 class="fb-h1">Explore Europe with Eurail – Seamless Train Travel for Every Journey</h1>

                <p>Eurail services from {{clientAbbr}} Travel Agency provide travel agents with unmatched ease and flexibility to
                    let clients access Europe´s extensive rail network. Whether zooming at bullet train speeds or taking
                    slower tracks on the local lines, Eurail passes let you travel through countries with a single fare
                    making for some amazing experiences across varied terrains and urban centers.</p>
                <a routerLink="/static-pages/eurail" class="btn btn-lg bnr-reg-btn border ">EXPLORE</a>
            </div>
            <div class="col-lg-5 order-lg-1 order-0">
                <img src="{{imageUrl+'assets/images/products/eurail-banner-img.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
