import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { commonurls } from '../../commonBaseUrls';


@Component({
  selector: 'app-product-hotel',
  templateUrl: './product-hotel.component.html',
  styleUrl: './product-hotel.component.scss'
})
export class ProductHotelComponent {
  public imageUrl = environment?.imageUrl;
  public websiteUrl = commonurls?.websiteUrl;
  public clientAbbr = environment?.clientAbbr;
  accordionData = [
    {
      id: 'flush-collapseOne',
      headingId: 'flush-headingOne',
      title: 'Q: How does the B2B hotel booking portal benefit travel agents in terms of profitability?',
      content: `A: Our portal provides travel agents direct access to exclusive B2B rates. It maximizes their profit margins and ensures they secure the best deals for their clients.`,
      expanded: true
    },
    {
      id: 'flush-collapseTwo',
      headingId: 'flush-headingTwo',
      title: " Q: Can I expect a diverse range of accommodation options on the portal to meet my clients' varied preferences and budgets?",
      content: ` A: Absolutely. The portal offers an extensive inventory of hotels, providing a wide array of choices to cater to diverse client needs and budget considerations.`,
      expanded: false
    },
    {
      id: 'flush-collapseThree',
      headingId: 'flush-headingThree',
      title: 'Q: How does the portal address the challenge of fluctuating hotel availability and rates?',
      content: `A: Real-time updates are a key feature of our portal, keeping travel agents informed about the latest information on hotel availability, rates, and promotions, thus, eliminating uncertainties.`,
      expanded: false
    },
    {
      id: 'flush-collapseFour',
      headingId: 'flush-headingFour',
      title: 'Q: How does the B2B hotel booking portal address concerns about the security of transactions?',
      content: `A: Security is a top priority. Our portal incorporates robust security measures to ensure that all transactions are safe and secure, providing peace of mind for travel agents and their clients.`,
      expanded: false
    },
    {
      id: 'flush-collapseFive',
      headingId: 'flush-headingFive',
      title: '5. Is the portal user-friendly for agents with varying levels of expertise?',
      content: `Absolutely. The B2B flight booking portal is designed with a user-friendly interface, making it easy for travel agents of all levels of expertise to navigate, search for flights, and manage bookings efficiently.`,
      expanded: false
    },
    {
      id: 'flush-collapseSix',
      headingId: 'flush-headingSix',
      title: '6. How does the portal ensure the security of financial transactions?',
      content: `The portal ensures secure transactions by offering various payment options and employing advanced security measures. Travel agents can trust that their financial information is handled with the utmost care and security.`,
      expanded: false
    }
  ];

  toggleAccordion(index: number): void {
    this.accordionData.forEach((item, i) => {
      item.expanded = i === index ? !item.expanded : false; // Toggle the clicked item and collapse others
    });
  }
}
