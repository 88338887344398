import { Component } from '@angular/core';
import { commonurls } from '../../commonBaseUrls';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-termsofservice',
  templateUrl: './termsofservice.component.html',
  styleUrl: './termsofservice.component.scss'
})
export class TermsofserviceComponent {
  public clientEmail = commonurls?.clientEmail;
  public websiteUrl = commonurls?.websiteUrl;
  public clientAbbr = environment?.clientAbbr;

}
