import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { commonurls } from '../../commonBaseUrls';


@Component({
  selector: 'app-product-holidays',
  templateUrl: './product-holidays.component.html',
  styleUrl: './product-holidays.component.scss'
})
export class ProductHolidaysComponent {
  public imageUrl = environment?.imageUrl;
  public websiteUrl = commonurls?.websiteUrl;
  public liveUrl = commonurls?.liveUrl;
  public clientAbbr = environment?.clientAbbr;


}
