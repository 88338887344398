import { Component } from '@angular/core';
import { commonurls } from '../../commonBaseUrls';

@Component({
  selector: 'app-termsandconditions',
  templateUrl: './termsandconditions.component.html',
  styleUrl: './termsandconditions.component.scss'
})
export class TermsandconditionsComponent {
  public websiteUrl = commonurls?.websiteUrl;
  public refundMail = commonurls?.refundMail;
  public refundInMail = commonurls?.refundInMail;
  public liveUrl = commonurls?.liveUrl;
}
