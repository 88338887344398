<section class="inner-con pt-lg-4 pt-3">
   <div class="container">
      <div class="h5 mb-3">Refund Policy</div>
       <p class="fst-italic">This Refund Policy outlines the terms under which refunds will be processed by <a [href]="liveUrl" target="_blank">{{websiteUrl}}</a>, represented by its entities in the USA, Canada, India, UAE, and the UK. Each entity abides by the respective laws and regulations of its country of operation. By transacting with  <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase }}</a>, you agree to the terms outlined below.</p>
       <hr>
       <div class="h5 blue-text fw-bold">1.General Refund Process</div>
      
      <ul style="list-style:decimal">
            <li class="fw-bold">Initiation of Refund:</li>
           <ul class="fst-italic mb-2">
              <li>Refunds will be processed only after receiving a written cancellation request from the travel agent, corporate client, or customer via email.</li>
               <li>Refund requests must include all necessary details, such as booking reference numbers and cancellation confirmations.</li>
           </ul>
           <li class="fw-bold">Mode of Refund:</li>
           <ul class="fst-italic mb-2">
              <li>Refunds will be credited directly to the customer's original mode of payment after receiving the refunded amount from the airline, hotel, transporter, or other suppliers.</li>
               <li>For bookings made through travel agents or corporate accounts, the refund amount will be updated in the respective <strong>Portal Wallet</strong>. Direct transfers to credit cards or bank accounts are not applicable in such cases.</li>
           </ul>
           <li class="fw-bold">Processing Timeline:</li>
           <ul class="fst-italic mb-2">
              <li >Refunds will be initiated within 72 hours after receiving the refund amount from the concerned airline, hotel, or supplier.</li>
               <li>Due to banking procedures:</li>
               <ul class="fst-italic mb-2">
                  <li class="fw-bold">Refunds for credit card transactions may take up to 72 hours to appear in the customer’s account</li>
                   <li><span class="fw-bold">Net banking refunds</span> may take up to <span class="fw-bold">7 working days</span>.</li>
                   <li><span class="fw-bold">Failure bookings</span> or unsuccessful payment uploads will be refunded to the original payment mode within <span class="fw-bold">7–10 working days</span>.</li>
               </ul>
           </ul>
           <li class="fw-bold">Service Fee Deduction:</li>
           <ul class="fst-italic mb-2">
              <li><span class="fw-bold">Non-Refundable Service Fees:</span> The service fee charged at the time of booking is non-refundable, even in cases of cancellations.</li>
               <li><span class="fw-bold">Processing Fee:</span> A refund processing fee will be charged by <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a>, which will be deducted from the refundable amount.</li>
           </ul>
      </ul>
      <hr>
       <div class="h5 blue-text fw-bold">2.Product-Specific Refund Policies</div>
       <ul class="fst-italic mb-2" style="list-style:decimal">
          <li class="fw-bold">Flights</li>
           <ul class="fst-italic mb-2">
              <li>Refunds for flight cancellations will only be processed after receiving the refund from the airline, subject to the airline's refund and cancellation policies.</li>
               <li>In case of non-refundable tickets, only taxes (if applicable) will be refunded.</li>
           </ul>
           <li class="fw-bold">Hotels</li>
           <ul class="fst-italic mb-2">
              <li>Hotel booking refunds are subject to the hotel's specific cancellation policy.</li>
               <li>Non-refundable hotel rates will not qualify for refunds, except where explicitly allowed by the supplier.</li>
               
           </ul>
           <li class="fw-bold">Holiday Packages</li>
           <ul class="fst-italic mb-2">
              <li>Refunds for holiday packages will be based on the individual cancellation terms of flights, hotels, and other services included in the package.</li>
           </ul>
           <li class="fw-bold">Visa Services</li>
           <ul class="fst-italic mb-2">
              <li>Visa fees are non-refundable once the application has been submitted to the embassy or consulate, regardless of approval or rejection.</li>
            </ul>
            <li class="fw-bold">Travel Insurance</li>
           <ul class="fst-italic mb-2">
              <li>Travel insurance policies are non-refundable after issuance unless explicitly allowed by the insurance provider.</li>
            </ul>
               <li class="fw-bold">Bus Bookings and Eurail Passes</li>
           <ul class="fst-italic mb-2">
              <li>Refunds will be processed according to the respective operator's cancellation policies.</li>
               <li>Non-refundable passes and tickets will not qualify for refunds.</li>
            </ul>
       </ul>
       <hr>
       <div class="h5 blue-text fw-bold">3.Insolvency or Bankruptcy of Suppliers</div>
       <ul style="list-style:decimal">
          <li class="fw-bold">Flights, Hotels, and Other Suppliers</li>
           <ul>
              <li>In the event of insolvency or bankruptcy of an airline, hotel, or any other supplier, <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> will not be liable for refunds.</li>
               <li>Refunds will be considered only if the airline, hotel, or supplier promises to refund the amount and provides written confirmation.</li>
           </ul>
           <li class="fw-bold">Recovery of Refunded Amount:</li>
           <ul>
              <li>In cases where <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> processes a refund based on supplier assurance, the agency reserves the right to recover the refunded amount if the airline, hotel, or supplier becomes non-operational, insolvent, or bankrupt.</li>
           </ul>
       </ul>
        <hr>
       <div class="h5 blue-text fw-bold">4. Jurisdiction-Specific Regulations</div>
       <ul style="list-style:decimal;">
          <li class="fw-bold">USA</li>
           <p class="fst-italic">Refunds will comply with applicable airline and travel industry regulations as governed by the U.S. Department of Transportation. Processing times may vary based on state laws and banking procedures.</p>
           <li class="fw-bold">Canada</li>
           <p class="fst-italic">Refunds will adhere to Canadian Transportation Agency regulations. For credit card refunds, timelines are governed by local banking norms in Alberta or the province of purchase.</p>
           <li class="fw-bold">India</li>
           <p class="fst-italic">Refunds will follow guidelines issued by the Directorate General of Civil Aviation (DGCA) and the Reserve Bank of India (RBI) for transactions processed in India.</p>
            <li class="fw-bold">UAE</li>
           <p class="fst-italic">Refunds will comply with the UAE Consumer Protection Law and other applicable regulations. Delays caused by suppliers will be communicated promptly to the customer.</p>
           <li class="fw-bold">UK</li>
           <p class="fst-italic">Refunds will follow regulations outlined by the Civil Aviation Authority (CAA) and UK Consumer Rights Law, including specific guidelines for travel and holiday package cancellations.</p>
       </ul>
       <hr>
       <div class="h5 blue-text fw-bold">4. Important Notes</div>
       <ul style="list-style:decimal;">
          <li class="fw-bold">Non-Refundable Service Fees</li>
           <ul class="fst-italic">
              <li>The service fee collected during the booking process is non-refundable. This applies to all products, including flights, hotels, holiday packages, visas, travel insurance, bus bookings, and Eurail passes.</li>
           </ul>
           <li class="fw-bold">Processing Fees</li>
           <ul class="fst-italic">
              <li>A separate processing fee may be applied for handling refund requests, which will be deducted from the refunded amount.</li>
           </ul>
           <li class="fw-bold">Urgent Cancellations</li>
           <ul class="fst-italic">
              <li>For urgent cancellations, customers or agents are advised to contact the airline, hotel, or service provider directly. <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase}}</a> will not be held liable for no-shows or delays in cancellations.</li>
           </ul>
           <li class="fw-bold">Force Majeure</li>
           <ul class="fst-italic">
              <li>Refunds for disruptions caused by events beyond the control of <a [href]="liveUrl" target="_blank">{{websiteUrl|lowercase }}</a>, such as natural disasters or political unrest, will be subject to the terms and policies of the respective supplier.</li>
           </ul>
           <li class="fw-bold">Updates to Refund Policy</li>
           <ul class="fst-italic">
              <li>This policy may be updated periodically to comply with changes in laws and regulations in the USA, Canada, India, UAE, and the UK.</li>
           </ul>
       </ul>
       <hr>
      <div class="h5 mb-3 fw-bold"> Contact Information for Refunds</div>
      <p class="fst-italic">For assistance with refunds, please reach out to the respective country office:</p>
      <div class="table-responsive">
            <table width="100%" border="0" class="table table-bordered" cellspacing="0" cellpadding="0">
           <thead>
             <tr>
               <th>Country</th>
               <th>Email ID</th>
               <th>Submitted in between</th>
             </tr>
           </thead>
           <tbody>
             <tr>
               <td>India</td>
               <td><a [href]="'mailto:' + refundInMail">{{refundInMail}}</a></td>
               <td>10 AM to 07:00 PM  IST</td>
             </tr>
             <tr>
               <td>USA</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  CST</td>
             </tr>
             <tr>
               <td>Canada</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  CST</td>
             </tr>
             <tr>
               <td>UK</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  GMT</td>
             </tr>
             <tr>
               <td>UAE</td>
               <td><a [href]="'mailto:' + refundMail">{{refundMail}}</a></td>
               <td>10 AM to 07:00 PM  GST</td>
             </tr>
             </tbody>
           </table>

      </div>
      <p class="fst-italic">By using our services, you agree to and accept the provisions outlined in this Refund Policy.</p>
   </div>
</section>