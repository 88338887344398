import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { HeaderService } from "src/app/core/services/header.service";
import { StorageService } from "src/app/core/services/storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'BTA-business-section',
  templateUrl: './business-section.component.html',
//   styleUrls: ['./business-section.component.scss']
})

export class BusinessSectionComponent {
public image_url = environment?.imageUrl
}