<!--Banner section starts here-->
<div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <h1>{{clientAbbr}} Online Hotel Booking Got Simpler</h1>

                <p>
                    Log in to our travel agency {{clientAbbr}} hotel booking system to get massive hotel information at
                    your fingertips. It is reliable, secure, and 24/7 availability.
                </p>
                <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
            <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/hotel-banner.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<!--Banner section ends here-->

<section class="home-section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <img src="{{imageUrl+'assets/images/products/BTAGlobalNetwork.svg'}}" class="img-fluid">
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center">


                <p>
                    Travel agents often struggle with lengthy and complicated hotel booking procedures, It will consume
                    their valuable time and ultimately affect overall efficiency.On the other hand, securing competitive
                    rates may not be easy since most agents do not have direct access to exclusive {{clientAbbr}} rates,
                    thereby affecting their profit margins. They are often forced to settle over limited inventory
                    options, which may eventually fail to cater to the varied preferences and budgets of their clients.
                </p>
                <p>
                    Such fluctuating hotel availability and rates sometimes create uncertain and frustrating moments
                    when people can’t get consistent and reliable service from the agents.
                </p>
                <p>
                    Inability to directly communicate with hotel partners negates the possibility of building strong
                    relationships and getting personalized support; it adds fuel to the fire, and finally, the travel
                    agent gets easily frustrated with the business.
                </p>
            </div>

        </div>
    </div>
</section>

<section class="home-section bg-white">
    <div class="container pt-4 pb-4">
        <h2 class="text-center mb-4">
            How can our {{clientAbbr}} hotel booking portal for travel agents enhance productivity and reduce errors?
        </h2>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/hotel-booking.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Hotel booking made easy</h5>
                    <p class="mb-0">
                        Our cheapest hotel booking portal for travel agents always offers an efficient booking
                        experience with a user friendly interface.It makes it simple for travel agents to search,
                        compare, and arrange accommodation.
                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Exclusive-rates.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Exclusive rates at your fingertip</h5>
                    <p class="mb-0">
                        Our hotel booking portal provides direct access to exclusive {{clientAbbr}} rates and it ensures
                        that travel agents get the best deals and enhance their profit margins.

                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Diverse-Accommodation.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Diverse Accommodation Options</h5>
                    <p class="mb-0">
                        A travel agency has a different customer base.Our vast hotel inventory helps travel agents wide
                        range of choices from affordable to expensive accommodation choices.

                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Real-TimeUpdates.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Real-Time Updates</h5>
                    <p class="mb-0">
                        Our hotel reservation website offers real time rates and exclusive offers. Thus, a travel agent
                        may make choices easily.


                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Advanced-Technology.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Advanced Technology</h5>
                    <p class="mb-0">
                        Integration of advanced technology within the portal equips travel agents with tools such as
                        analytics and insights, providing a competitive edge.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-section pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center">
                <h3 class="fw-bold">Who are we ?</h3>

                <p>
                    Welcome to {{clientAbbr}} Travel Agency India Pvt Ltd, a trusted and IATA accredited travel
                    consolidator at the forefront of the travel industry. As the flagship company of Trip Brands LLC, a
                    global travel group, we are dedicated to revolutionizing modern travel technology.
                </p>
                <p>With a vision to bring order to the unorganized travel sectors, BTA-India stands out as a leading
                    consolidator in India. Our strong partnerships with major airlines and suppliers, coupled with a
                    nationwide presence, underscore our influence in the industry.</p>
                <p>
                    At BTA-India, we embrace modern technology to organize and empower the travel experience. Through
                    our online platform, {{websiteUrl}}, we offer a 'one-stop-shop' for our trade partners, seamlessly
                    integrating air tickets, hotel bookings, visa services, holiday packages, and more.
                </p>


            </div>
            <div class="col-lg-5">
                <img src="{{imageUrl+'assets/images/products/StrategicAlliancesBTAsHotelNetwork.svg'}}"
                    class="img-fluid">
            </div>
        </div>
        <p>Our unique and efficient business model prioritizes the growth and profitability of our partners. BTA ensures
            dependable service, streamlined operational processes, and a diverse range of products—all with a guarantee
            of value for money. Committed to international standards, integrity, and financial stability, we pride
            ourselves on being a trusted organization.</p>
        <p>At BTA, we continuously strive for innovation and adaptability, responding proactively to meet the evolving
            needs of our valued business partners. Join us on a journey of measured growth, increased profitability, and
            a commitment to excellence in every aspect of the travel experience.</p>




    </div>
</section>

<section class="home-section bg-white">
    <div class="container pt-4 pb-4">
        <h2 class="text-center mb-4">
            Why is our best {{clientAbbr}} hotel booking portal a travel agent’s favourite?
        </h2>
        <div class="row ">
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <div align="center"><img src="{{imageUrl+'assets/images/products/Maximum-Profits.svg'}}" height="80"
                            class="mb-3"></div>
                    <h5 class="fw-bold">Maximum Profits</h5>
                    <p class="mb-0">
                        Travel agents can access exclusive rates, which helps achieve higher profit margins for your
                        business.
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{imageUrl+'assets/images/products/Diverse-Choices.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Diverse Choices </h5>
                    <p class="mb-0">
                        We provide an extensive range of hotels to suit a variety of preferences and price budgets. A
                        travel agent can cater to different clients.
                    </p>
                </div>
            </div>


        </div>
        <div class="row ">
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{imageUrl+'assets/images/products/User-Friendly-Interface-hotel.svg'}}" height="80"
                        class="mb-3">
                    <h5 class="fw-bold">User-Friendly Interface</h5>
                    <p class="mb-0">
                        Our online portal for hotel booking is user-friendly. It helps travel agents with minimal
                        technical knowledge to navigate easily and it enhances overall productivity.
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <div align="center"><img src="{{imageUrl+'assets/images/products/Better-Customer-Service.svg'}}"
                            height="80" class="mb-3"></div>
                    <h5 class="fw-bold">Better Customer Service</h5>
                    <p class="mb-0">
                        We guarantee the highest quality of service for our clients. We don’t leave them alone and
                        answer their queries promptly. It makes our {{clientAbbr}} hotel booking portal travel agent’s
                        favourite.
                    </p>
                </div>
            </div>



        </div>
    </div>
</section>

<section class="home-section pt-5 pb-5 bg-light">
    <h3 class="fw-bold text-center mb-5">
        How does our Online portal for hotel booking work?
    </h3>
    <div class="container">
        <div class="row mb-lg-4">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Sign-Up-user.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">User Registration</h5>
                    <p class="mb-0">
                        Create accounts on the hotel booking portal by providing basic information like name, email, and
                        password.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/fill-details.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Search and Selection</h5>
                    <p class="mb-0">
                        Enter destination, check-in, and check-out dates. The portal displays a list of available hotels
                        based on the user's criteria. A travel agent can filter and sort results based on preferences
                        such as price, rating, and amenities.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Room-Selection.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Room Selection</h5>
                    <p class="mb-0">
                        Choose a specific hotel and browse available room types. Information about each room, including
                        price and amenities, is displayed.
                    </p>
                </div>
            </div>

        </div>
        <div class="row mb-lg-4">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Booking-Confirmation.svg'}}" height="100" class="mb-2">
                    <h5 class="fw-bold">Booking Confirmation</h5>
                    <p class="mb-0">
                        Once a room is selected, the travel agent proceeds to the booking page. There you will get
                        payment details and confirm the reservation.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Payment-Processing.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Payment Processing</h5>
                    <p class="mb-0">
                        The portal securely processes the payment using the provided payment method (credit card, etc.).
                    </p>
                    <p>Travel agents receive a confirmation email with booking details.</p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Reservation-Management.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Reservation Management</h5>
                    <p class="mb-0">
                        To track and handle their reservations, travel consultants can access their accounts by logging
                        in. This includes the ability to modify booking dates or cancel reservations (subject to terms
                        and conditions).
                    </p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Communication.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Communication</h5>
                    <p class="mb-0">
                        The portal may send email or SMS notifications to the travel consultant with booking
                        confirmation, reminders, and other relevant information.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Commission-for-Travel-Agents.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Commission for Travel Agents</h5>
                    <p class="mb-0">
                        Travel agents, who refer customers to the portal, earn a good commission on each successful
                        booking.
                    </p>
                </div>
            </div>


        </div>





    </div>
</section>

<section class="home-section bg-white pt-5 pb-5">
    <div class="container">
        <h2 class="text-center">FAQ</h2>


        <!-- <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                      <button class="accordion-button shadow-none fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                          Q: How does the {{clientAbbr}} hotel booking portal benefit travel agents in terms of profitability?
  
  
                      </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                          A: Our portal provides travel agents direct access to exclusive {{clientAbbr}} rates. It maximizes their profit margins and ensures they secure the best deals for their clients.
                      </div>
                  </div>
              </div>
              <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                      <button class="accordion-button shadow-none fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                          Q: Can I expect a diverse range of accommodation options on the portal to meet my clients' varied preferences and budgets?
  
                      </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                          A: Absolutely. The portal offers an extensive inventory of hotels, providing a wide array of choices to cater to diverse client needs and budget considerations.
                      </div>
                  </div>
              </div>
              <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                      <button class="accordion-button shadow-none fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                          Q: How does the portal address the challenge of fluctuating hotel availability and rates?
  
                      </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                          A: Real-time updates are a key feature of our portal, keeping travel agents informed about the latest information on hotel availability, rates, and promotions, thus, eliminating uncertainties.
                      </div>
                  </div>
              </div>
              <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingFour">
                      <button class="accordion-button shadow-none fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                          Q: How does the {{clientAbbr}} hotel booking portal address concerns about the security of transactions?
  
  
                      </button>
                  </h2>
                  <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">A: Security is a top priority. Our portal incorporates robust security measures to ensure that all transactions are safe and secure, providing peace of mind for travel agents and their clients.</div>
                  </div>
              </div>
  
  
          </div> -->
        <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item" *ngFor="let item of accordionData; let i = index">
                <h2 class="accordion-header" [id]="item.headingId">
                    <button class="accordion-button shadow-none fw-bold" [class.collapsed]="!item.expanded"
                        type="button" (click)="toggleAccordion(i)" [attr.aria-expanded]="item.expanded"
                        [attr.aria-controls]="item.id">
                        {{ item.title }}
                    </button>
                </h2>
                <div class="accordion-collapse collapse" [class.show]="item.expanded" [id]="item.id"
                    [attr.aria-labelledby]="item.headingId" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">
                        {{ item.content }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>