import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { HeaderService } from "src/app/core/services/header.service";
import { StorageService } from "src/app/core/services/storage.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'BTA-stat-section',
  templateUrl: './stat-section.component.html',
 styleUrls: ['./stat-section.component.scss']
})

export class StatSectionComponent {
public image_url = environment?.imageUrl
}