import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-explore-booking-engine',
  templateUrl: './explore-booking-engine.component.html',
  styleUrl: './explore-booking-engine.component.scss'
})
export class ExploreBookingEngineComponent {
  public imageUrl = environment?.imageUrl;
  public clientAbbr = environment?.clientAbbr;

}
