import { Component } from '@angular/core';
import { commonurls } from '../../commonBaseUrls';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  public clientEmail = commonurls?.clientEmail;
  public clientAbbr = environment?.clientAbbr;

}
