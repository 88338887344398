<section class="inner-con pt-lg-4 pt-3">
   <div class="container">
      <div class="h5 mb-3">Terms of Service</div>
      <div class="h6 blue-text fw-bold">1. Overview</div>
      <p class="fst-italic">{{clientAbbr}} Travel Agency (BTA) is a comprehensive online travel service platform
         designed to meet the needs of travel agencies and suppliers. Operating under the umbrella of Tripbrands LLC, a
         global travel technology provider, BTA aims to simplify travel business operations by offering all essential
         services under one platform.</p>
      <p>By using {{websiteUrl}}, you agree to these Terms of Service. You should stop using the platform right away if
         you disagree.</p>
      <hr>
      <div class="h6 blue-text fw-bold">2. Services for Travel Agencies</div>
      <p class="fst-italic">BTA provides a user-friendly platform to help travel agencies access:</p>
      <ul class="fst-italic">
         <li>Competitive deals on flights (domestic and international), hotels, and vacation packages.</li>
         <li>Travel solutions leveraging cutting-edge technology to enhance customer service.</li>
         <li>Simplified booking processes to meet diverse customer needs efficiently.</li>
      </ul>
      <div class="h6">Responsibilities of Travel Agencies:</div>
      <ul class="fst-italic">
         <li>Ensure the accuracy of booking details provided on the platform.</li>
         <li>Use the platform for legitimate travel-related business operations only.</li>
         <li>Adhere to all applicable laws and regulations governing your business activities.</li>
      </ul>
      <hr>
      <div class="h6 blue-text fw-bold">3. Services for Suppliers</div>
      <p class="fst-italic">BTA offers suppliers, such as hotels and other service providers, the opportunity to:</p>
      <ul>
         <li>List and sell their inventory through the BTA platform.</li>
         <li>Leverage BTA's extensive network of travel agents and marketing to expand their reach.</li>
         <li>Increase sales by making products and services available to a broader audience.</li>
      </ul>
      <div class="h6">Responsibilities of Suppliers:</div>
      <ul class="fst-italic">
         <li>Ensure that inventory and pricing information is accurate and current.</li>
         <li>Deliver services as promised to customers and agents.</li>
         <li>Comply with all relevant legal and regulatory requirements for their offerings.</li>
      </ul>
      <hr>
      <div class="h6 blue-text fw-bold">4. Use of the Platform</div>
      <div class="h6">Eligibility:</div>
      <p class="fst-italic">The platform is intended for use by licensed travel agencies, suppliers, and businesses
         involved in the travel industry. Users must be authorized representatives of their respective organizations.
      </p>
      <div class="h6">Prohibited Activities:</div>
      <p class="fst-italic">Users agree not to:</p>
      <ul class="fst-italic">
         <li>Misuse the platform for fraudulent or illegal activities.</li>
         <li>Submit false, misleading, or incomplete information.</li>
         <li>Interfere with the usual operation of the platform.</li>
      </ul>
      <div class="h6">Access and Termination:</div>
      <p class="fst-italic">BTA reserves the right to:</p>
      <ul>
         <li>Restrict access to the platform for any user violating these Terms of Service.</li>
         <li>Modifying or stopping services at any time.</li>
      </ul>
      <hr>
      <div class="h6 blue-text fw-bold">5. Liability Disclaimer</div>
      <div class="h6">For Travel Agencies and Suppliers:</div>

      <ul class="fst-italic">
         <li>BTA acts solely as an intermediary platform connecting travel businesses and suppliers.</li>
         <li>BTA is not liable for any disputes, service deficiencies, or other issues arising between travel agencies,
            suppliers, or their customers.</li>
      </ul>
      <div class="h6">Platform Accuracy:</div>

      <ul class="fst-italic">
         <li>BTA strives to provide accurate and up-to-date information but does not guarantee the completeness or
            reliability of any content or deals on the platform.</li>
      </ul>
      <div class="h6">Limitations of Liability:</div>
      <p class="fst-italic">To the maximum extent allowed by law, BTA will not be responsible for any::</p>
      <ul class="fst-italic">
         <li>The platform is not liable for any damages incurred through its use, whether they are direct, indirect,
            incidental, punitive, or consequential.</li>
         <li>Losses due to service interruptions, inaccuracies, or errors.</li>
      </ul>
      <hr>

      <div class="h6 blue-text fw-bold">6. Intellectual Property</div>
      <p class="fst-italic">All content, trademarks, and materials on {{websiteUrl}} are the intellectual property of
         BTA or its licensors. Users may not copy, modify, or distribute content without prior written consent.</p>
      <hr>
      <div class="h6 blue-text fw-bold">7. Modifications to the Terms</div>
      <p class="fst-italic">BTA reserves the right to update or amend these Terms of Service at any time. By continuing
         to use the platform, you agree to the updated terms and conditions.</p>
      <hr>
      <div class="h6 blue-text fw-bold">8. Governing Law and Disputes</div>
      <p class="fst-italic">The laws of the Government of India govern these terms of service. Any disputes arising from
         the use of the platform shall be resolved through arbitration or the courts of Cochin, Kerala, India.</p>
      <hr>
      <div class="h6 blue-text fw-bold">9. Contact Information</div>
      <p class="fst-italic">For any questions or concerns regarding these Terms of Service, please feel free to reach
         out to:</p>
      <p><strong>Email:</strong> <a [href]="'mailto:' + clientEmail">{{clientEmail}}</a></p>
      <p><strong>Phone:</strong> <a href="tel:022-69693333">022-69693333</a></p>
      <p><strong>Address:</strong></p>
      <p class="fst-italic">{{clientAbbr}} Travel Agency India Pvt Ltd<br>
         A803, 8th Floor BSEL Tech Park, Sector 30A Vashi, Navi Mumbai 400705<br>

      <p class="lead fst-italic">By using <strong>{{websiteUrl}}</strong>, you agree to these Terms of Service.</p>

   </div>
</section>