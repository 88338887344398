import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { commonurls } from '../../commonBaseUrls';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrl: './contactus.component.scss'
})
export class ContactusComponent {
  public imageUrl = environment?.imageUrl;
  public clientEmail = commonurls?.clientEmail;
  public clientAbbr = environment?.clientAbbr;
}
