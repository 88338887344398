<nav class="navbar navbar-expand-lg bg-body" id="header">
    <div class="container">
        <a class="navbar-brand" routerLink=""><img src="{{imageUrl+logoUrl}}" [height]="logoHeight"></a>

        <div class="navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto">
                <li class="nav-item" id="login">
                    <a class="nav-link link" href="https://old.b2btravelagency.com/">Old B2B travel Agency Link</a>
                </li>
                <li class="nav-item" id="login">
                    <a class="nav-link" (click)="navigateToLogin()">Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="registration" routerLink="/account/auth/signup">Register</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" style="display: none;" id="home" routerLink="/">Home</a>
                </li>
                <li class="nav-item">
                    <ul class="navbar-nav flex-row ml-auto mr-0">

                        <li class="nav-item cpr" (click)="toggleCountryDropdown()">
                            <div class="position-relative">
                                <a class="nav-link">
                                    <!--                     -->
                                    <div class="btn-flag-icon"
                                        [style.background]="'url(' + selectedCountry?.flag + ') no-repeat center / cover'">
                                    </div>
                                </a>
                                <div #dropDown class="countrydrop-con shadow"
                                    style="display: none; top: 69px; right: 0px;z-index: 9999;">
                                    <ul>
                                        <li *ngFor="let item of countries">
                                            <a class="d-flex align-items-center cpr p-0" (click)="selectCountry(item)"
                                                [ngClass]="{'active': item.name == selectedCountry?.name}">
                                                <div class="bg-flag"
                                                    [style.background]="'url(' + item.flag + ') no-repeat center / cover'">
                                                </div>
                                                {{item.name}}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</nav>