import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-product-visa',
    templateUrl: './product-visa.component.html',
    styleUrls: ['./product-visa.component.scss']
})

export class ProductVisaComponent {
    public image_baseUrl = environment?.imageUrl;
    public clientAbbr = environment?.clientAbbr;
}