import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-explore-distribution',
  templateUrl: './explore-distribution.component.html',
  styleUrl: './explore-distribution.component.scss'
})
export class ExploreDistributionComponent {
  public imageUrl = environment?.imageUrl;
  public clientAbbr = environment?.clientAbbr;


}
