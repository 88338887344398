import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { commonurls } from '../../commonBaseUrls';

@Component({
  selector: 'app-company-bulleting',
  templateUrl: './company-bulleting.component.html',
  styleUrl: './company-bulleting.component.scss'
})
export class CompanyBulletingComponent {
  public imageUrl = environment?.imageUrl;
  public websiteUrl = commonurls?.websiteUrl;
  public clientAbbr = environment?.clientAbbr;
}
