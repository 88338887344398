import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: 'BTA-mid-section',
  templateUrl: './mid-section.component.html',
  styleUrls: ['./mid-section.component.scss']
})

export class MidSectionComponent {

  public clientAbbr = environment?.clientAbbr;

}