<div class="container bta-footer" id="footer">
    <div class="row">
        <div class="col-lg-4 col-12">
            <img src="{{imageUrl+logoUrl}}" [height]="logoHeight">
            <p class="small fw-medium mt-3">© 2024 {{clientAbbr}} Travel Agency – All rights reserved</p>
        </div>
        <div class="col-lg-8 col-12 text-lg-end">
            <div class="mt-lg-5"><a routerLink="/static-pages/company">Company</a> <a
                    routerLink="/static-pages/products">Products </a> <a routerLink="/static-pages/contact">Contact</a>
                <a routerLink="/static-pages/faq">FAQ</a>
            </div>
            <div class="mt-2 small">
                <a routerLink="/static-pages/privacy">Privacy Policy</a> | <a
                    routerLink="/static-pages/terms-of-service">Terms of Service</a> | <a
                    routerLink="/static-pages/tems-and-condition">Terms & Conditions</a>
                | <a routerLink="/static-pages/refund-policy">Refund Policy</a>
            </div>
        </div>
    </div>
</div>