import { Component } from "@angular/core";
import { cl } from "@fullcalendar/core/internal-common";

@Component({
    selector: 'BTA-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})

export class HomePageComponent {
    public loginStatus: Boolean = false;

    loginFlag(event) {
        this.loginStatus = event;
    }
}