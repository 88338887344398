<div class="home-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 d-flex flex-column justify-content-center">
                <h1>Easy {{clientAbbr}} Holiday Bookings</h1>

                <p>
                    Log into our vacation booking portal as a travel agent to get easy access to an international
                    variety of holiday packages. Use our secure, reliable service and maximize your productivity and
                    profitability since our portal is available 24/7.
                </p>
                <a routerLink="/account/auth/signup" class="btn btn-lg bnr-reg-btn">Register Now</a>
            </div>
            <div class="col-lg-6">
                <img src="{{imageUrl+'assets/images/products/holiday-banner.svg'}}" class="img-fluid">
            </div>
        </div>
    </div>
</div>
<!--Banner section ends here-->

<section class="home-section bg-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <img src="{{imageUrl+'assets/images/products/travel-agent.svg'}}" class="img-fluid">
            </div>
            <div class="col-lg-7 d-flex flex-column justify-content-center">
                <div class="h5 mb-4">
                    Problems That Travel Agents Experience When Booking Holiday Reservations
                </div>

                <p>
                    Travel agencies usually face issues of complex holiday booking procedures that leave them with a lot
                    of time and low productivity levels. Besides, an agency cannot easily gain access to very
                    competitive holiday package rates devoid of {{clientAbbr}} deal access, which influences profits.
                    Limited
                    package choices contribute to the difficulty of fitting most customer budgets and preferences
                    together.
                </p>
                <p>
                    It is difficult to offer guaranteed and consistent service when package availability and costs
                    change with every passing day, creating stressful situations. You cannot talk directly to the
                    suppliers of packages, and thus it becomes a challenging task to form any relations, which further
                    restricts personalized support and causes delays.
                </p>

            </div>

        </div>





    </div>
</section>

<section class="home-section bg-white">
    <div class="container pt-4 pb-4">
        <h2 class="text-center mb-4">
            How Our {{clientAbbr}} Holiday Booking Portal Improves Productivity?
        </h2>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Easy-and-Simple-Reservation.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Easy and Simple Reservation</h5>
                    <p class="mb-0">
                        Our vacation booking portal has an intuitive UI, making the process easier. Travel agents can
                        quickly search for, assess, and reserve travel packages, which saves time and reduces the chance
                        of errors.
                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Exclusive-rates.svg'}}" height="50">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Exclusive {{clientAbbr}} Prices</h5>
                    <p class="mb-0">
                        Direct access to special {{clientAbbr}} vacation package prices will assist travel agents
                        increase their
                        profit margins and find the best offers, ensuring a maximum return on each reservation.

                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Comprehensive-Package-Options.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Comprehensive Package Options</h5>
                    <p class="mb-0">
                        We serve a vast group of customers through our vast inventory of holidays. We can cater to a
                        vast majority as well as their pocket size by offering luxury holidays, adventure holidays,
                        family holidays, and budget vacations.

                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Real-TimeUpdates.svg'}}" height="50">
                </div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Real-time accessibility</h5>
                    <p class="mb-0">
                        The up-to-date information on the offers of holiday deals, holiday prices, and offers, would
                        enable the travel agent to take fast decisions and this would in turn easily adapt to the
                        conditions that change with the market.
                    </p>
                </div>
            </div>
        </div>
        <div class="border rounded-4 mb-2 p-3">
            <div class="row">
                <div class="col-lg-1 col-3" align="center"><img
                        src="{{imageUrl+'assets/images/products/Advanced-Technology.svg'}}" height="50"></div>
                <div class="col-lg-11 col-9">
                    <h5 class="fw-bold">Advanced Technology</h5>
                    <p class="mb-0">
                        By integrating cutting-edge resources such as analytics and insights, our portal allows travel
                        agencies the ability to enhance their strategy and outplay the competition in the marketplace.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-section pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 d-flex flex-column justify-content-center">
                <h3 class="fw-bold">Who Are We?</h3>

                <p>
                    Welcome to {{clientAbbr}} Travel Agency India Pvt Ltd - The leading IATA accredited travel
                    consolidator at the
                    head of this industry. This flagship business of the World Famous Powerhouse of Trip Brands LLC is
                    transforming Contemporary travel Technology.</p>
                <p>BTA-India is a consolidator that streamlines the convoluted travel industry by organizing and
                    coordinating travel. We have emerged as a highly prominent travel consolidator all over India on
                    account of our strong connections with suppliers and principal airlines and our massive countrywide
                    presence. On our site, <a [href]="liveUrl">{{websiteUrl}}</a>, we amalgamate air, hotel, visa and
                    holiday reservations
                    into one convenient "one-stop-shop" for our business associates.

                </p>



            </div>
            <div class="col-lg-5">
                <img src="{{imageUrl+'assets/images/products/StrategicAlliancesBTAsHotelNetwork.svg'}}"
                    class="img-fluid">
            </div>
        </div>
        <p>Our creative business plan is designed to make our partners more profitable and successful. We offer a great
            range of products, reliable services, and smooth processes-all while providing outstanding value for the
            money. BTA is a reliable partner in the travel sector because of its commitment to integrity, financial
            stability, and international standards.</p>
        <p>
            Innovation and flexibility are our top priorities, and we're always adjusting to satisfy our partners'
            shifting demands. Come expand with us in the future and experience unparalleled service excellence,
            increased productivity, and sustainable growth.
        </p>




    </div>
</section>

<section class="home-section bg-white">
    <div class="container pt-4 pb-4">
        <h2 class="text-center mb-4">
            Why Our {{clientAbbr}} Holiday Booking Platform Is a Travel Agent’s Favorite
        </h2>
        <div class="row ">
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <div align="center"><img src="{{imageUrl+'assets/images/products/Maximum-Profits.svg'}}" height="80"
                            class="mb-3"></div>
                    <h5 class="fw-bold">Maximum Profitability</h5>
                    <p class="mb-0">
                        Since travel agents operate with {{clientAbbr}} rates that are provided, profit margins stand at
                        very high
                        levels where business value is significant.
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{imageUrl+'assets/images/products/Diverse-Choices.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Wide Holiday Options</h5>
                    <p class="mb-0">
                        Our website contains an exhaustive list of holiday packages to suit the various requirements of
                        the clients, ranging from luxury to budget holidays.
                    </p>
                </div>
            </div>


        </div>
        <div class="row ">
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <img src="{{imageUrl+'assets/images/products/User-Friendly-Interface-hotel.svg'}}" height="80"
                        class="mb-3">
                    <h5 class="fw-bold">Intuitive Interface</h5>
                    <p class="mb-0">
                        Our user-friendly interface is designed for easy navigation, even for the most technically
                        challenged agents, so that they can easily navigate and manage bookings.
                    </p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="p-4 rounded-4 bg-light border border-info p-work-bx text-center mb-4">
                    <div align="center"><img src="{{imageUrl+'assets/images/products/Better-Customer-Service.svg'}}"
                            height="80" class="mb-3">
                    </div>
                    <h5 class="fw-bold">Exceptional Support</h5>
                    <p class="mb-0">
                        We provide support from a dedicated team that can respond to all your inquiries while ensuring
                        holiday bookings are always smooth.
                    </p>
                </div>
            </div>



        </div>
    </div>
</section>

<section class="home-section pt-5 pb-5 bg-light">
    <h3 class="fw-bold text-center mb-5">
        How our {{clientAbbr}} Holiday Booking Portal Works?
    </h3>
    <div class="container">
        <div class="row mb-lg-4">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Sign-Up-user.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">User Registration</h5>
                    <p class="mb-0">
                        User registration using the required information, namely, name, email, and a strong password.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/fill-details.svg'}}" height="100" class="mb-3">
                    <h5 class="fw-bold">Search & Compare</h5>
                    <p class="mb-0">
                        Using your travel dates and destination, you would find here a list of holiday packages
                        carefully prepared, filtered, and sorted by price, rating, and activities.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Room-Selection.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Package Selection</h5>
                    <p class="mb-0">
                        A holiday package appropriate to your need has been selected with the descriptions in detail,
                        including breakdown of price and available amenities.
                    </p>
                </div>
            </div>

        </div>
        <div class="row mb-lg-4">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Booking-Confirmation.svg'}}" height="100" class="mb-2">
                    <h5 class="fw-bold">Booking Confirmation </h5>
                    <p class="mb-0">
                        Confirm reservation by verifying the payment details, finalize the booking and ensure safe
                        payment execution.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Payment-Processing.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Payment Processing </h5>
                    <p class="mb-0">
                        safe to process and accepts various modes of payments. E-mail confirmation is sent to the travel
                        agents for reference.
                    </p>

                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Reservation-Management.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Reservation Management </h5>
                    <p class="mb-0">
                        Travel agent allows you to manage reservations using their dashboard, including modifications,
                        date changes or cancellations until the agreed terms and conditions.
                    </p>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Communication.svg'}}" height="90" class="mb-3">
                    <h5 class="fw-bold">Notifications & Updates</h5>
                    <p class="mb-0">
                        Receive timely updates via e-mail or SMS related to your bookings, special promotions and
                        reminders.
                    </p>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="p-4 border rounded-4 bg-white p-work-bx">
                    <img src="{{imageUrl+'assets/images/products/Commission-for-Travel-Agents.svg'}}" height="100"
                        class="mb-3">
                    <h5 class="fw-bold">Agency to Agents</h5>
                    <p class="mb-0">
                        Agents get impressive commission on all confirmed vacation packages that increase their
                        respective income.
                    </p>
                </div>
            </div>


        </div><br>
        <div class="h4 alert alert-info">Find the just right way of managing all holiday bookings and building the
            travel business with {{clientAbbr}} Holiday Booking Portal for Agencies by BTA.</div>

    </div>
</section>