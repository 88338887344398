import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SimplebarAngularModule } from 'simplebar-angular';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-ui-switch';

import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { VerticalComponent } from './vertical/vertical.component';
import { HorizontaltopbarComponent } from './horizontaltopbar/horizontaltopbar.component';
import { NzWaterMarkModule } from 'ng-zorro-antd/water-mark';
import { HomePageModule } from '../home-page/home-page.module';
import { SharedMaterialModule } from '../shared-material.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FooterComponent } from '../home-page/footer/footer.component';
import { FooterComponents } from './footer/footer.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [LayoutComponent, SidebarComponent,FooterComponents, TopbarComponent, RightsidebarComponent, HorizontalComponent, VerticalComponent, HorizontaltopbarComponent],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    SimplebarAngularModule,
    UiSwitchModule,
    NzWaterMarkModule,
    HomePageModule,
    SharedMaterialModule,
    MatSlideToggleModule,
    FooterComponent
  ]
})
export class LayoutsModule { }
