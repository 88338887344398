<div class="container">
    	<div class="h1 fw-bolder text-center mb-3">Your All in One <span class="red-text">Travel Partner</span> </div>
        <p class="text-center">Discover a seamless way to manage all your travel needs on a single platform. From budget-friendly hotel and flight bookings to your customers, customized holiday and business packages, we’ve got you covered. Enjoy hassle-free visa assistance, trusted travel insurance, and bus booking platform—all designed to make your B2b travel booking effortless and stress-free.</p>
        <div class="row mt-4">
        	<div class="col-lg-6">
            	<div class="row">
                	<div class="col-lg-4 col-4"><img src="{{image_url+'assets/images/flight/travelport.png'}}" class="img-fluid"></div>
                    <div class="col-lg-4 col-4"><img src="{{image_url+'assets/images/flight/Amadeus.png'}}" class="img-fluid"></div>
                    <div class="col-lg-4 col-4"><img src="{{image_url+'assets/images/flight/verteil.png'}}" class="img-fluid"></div>
                </div>
            </div>
            <div class="col-lg-6">
            	<div class="row">
                	 <div class="col-lg-4 col-4"><img src="{{image_url+'assets/images/flight/indigo.png'}}" class="img-fluid"></div>
                    <div class="col-lg-4 col-4"><img src="{{image_url+'assets/images/flight/spicejet.png'}}" class="img-fluid"></div>
                    <div class="col-lg-4 col-4"><img src="{{image_url+'assets/images/flight/giata.png'}}" class="img-fluid"></div>
                </div>
            </div>
        	
           
        </div>
    </div>